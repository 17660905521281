import './ScreenShot.scss';
import { useDispatch } from "react-redux";
import { useContext, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../state/hooks";
import { ButtonState } from "../shared/button-state/ButtonState";
import { setButtonState, setScreenshotButton } from "../../state/features/buttonStateSlice";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import html2canvas from 'html2canvas';
import { MapContext } from '../containers/map-container/map/MapContext';
import { setFeatureAttachments, setScreenshotFileNames, } from '../../state/features/digitizationSlice';

export const ScreenShot = () => {
    const dispatch = useDispatch();
    const map = useContext(MapContext);
    const canvasElement = useRef<HTMLCanvasElement | any>(null);
    const [href, setHref] = useState<any>();
    const [file, setFile] = useState<any>();
    const [aaa, setaaa] = useState<any>([]);
    const [fileName, setFileName] = useState<string>('');
    const [inputIsVisitable, setInputIsVisitable] = useState<boolean>(true);
    const [canvasCtx, setCanvasCtx] = useState<CanvasRenderingContext2D>();
    const buttonState = useAppSelector(state => state.buttonState.buttonState);
    const fields = useAppSelector((state) => state.digitization.fields);
    const featureAttachments: any = useAppSelector(state => state.digitization.featureAttachments);
    const screenshotFileNames: any = useAppSelector(state => state.digitization.screenshotFileNames);
  /*   const ScreenshotButton: any = useAppSelector(state => state.buttonState.screenshotButton); */
    let snippingBar = document.getElementById('snipping-bar')!;
    let canvasContext: CanvasRenderingContext2D;
    let lastMouseX: number = 0;
    let lastMouseY: number = 0;

    useEffect(() => {
        const canvas = canvasElement?.current;
        canvas!.width = 10000;
        canvas!.height = 10000;
        canvas!.strokeStyle = 'red';
        canvas!.fillStyle = 'blue';
        canvas!.position = 'absolute'
        canvasContext = canvas?.getContext('2d')
        setCanvasCtx(canvasContext)
        window.addEventListener("resize", windowResize);
        windowResize(canvasContext);
    }, [])


    //sağ click ile screenshot iptal ediliyor.
    useEffect(() => {
        document.addEventListener('contextmenu', () => {
            dispatch(setScreenshotButton(false))
        });
        return () => document.removeEventListener('contextmenu', () => { })
    }, [buttonState])

    const windowResize = (canvasContext: any) => {
        if (canvasContext.canvas) {
            const canvas = canvasContext!.canvas;
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        }
    };

    const mouseDown = (event: MouseEvent) => {
        if (event.buttons === 2) {
            onCloseScreenShot();
            return;
        }
        lastMouseX = event.clientX;
        lastMouseY = event.clientY;
        canvasElement?.current.addEventListener('mousemove', mouseMove);
    }

    const mouseMove = async (event: MouseEvent) => {
        const mouseX = event.clientX;
        const mouseY = event.clientY;

        const context = canvasCtx;
        const canvas = context!.canvas;

        const width = mouseX - lastMouseX;
        const height = mouseY - lastMouseY;

        context?.clearRect(0, 0, canvas.width, canvas.height); // clear canvas

        context?.beginPath();
        const squares = await getTransparentSquareList(
            lastMouseX,
            lastMouseY,
            mouseX,
            mouseY,
            canvas.width,
            canvas.height
        );

        context!.fillStyle = 'rgba(0, 0, 0, 0.45)';
        for (const square of squares) {
            // @ts-ignore
            context.fillRect(...square);
        }

        context!.fillStyle = 'rgba(255,0,0, 0)';
        context!.rect(lastMouseX, lastMouseY, width, height);

        context!.strokeStyle = 'red';
        context!.lineWidth = 2;
        context!.stroke();
        return () => {
            canvasElement.current.removeEventListener('mouse', mouseMove)
        }
    }

    const mouseUp = async (event: MouseEvent) => {
        canvasElement?.current.removeEventListener('mousemove', mouseMove);
        const mouseX = event.clientX;
        const mouseY = event.clientY;
        end(lastMouseX, mouseX, lastMouseY, mouseY);
    }
    let exportImageData2: any[] = []

    const end = async (startX: number, endX: number, startY: number, endY: number) => {
        const toolPadding = 10;
        const canvasElement = canvasCtx!.canvas;

        if (endY < startY) {
            const tempY = startY;
            startY = endY;
            endY = tempY;
        }
        if (endX < startX) {
            const tempX = startX;
            startX = endX;
            endX = tempX;
        }

        const thresholdValue = endY + toolPadding + 50;

        if (thresholdValue >= canvasElement.height) {
            snippingBar.style.left = endX - toolPadding + 'px';
            snippingBar.style.top = endY - toolPadding + 'px';
            snippingBar.style.transform = 'translate(-100%,-100%)';
        } else {
            snippingBar.style.left = endX + 'px';
            snippingBar.style.top = endY + toolPadding + 'px';
        }

        const tempCanvasElement: any = document.createElement('canvas');
        html2canvas(document.body, { logging: false })
            .then((exportCanvasElement) => {
                const lineWidth = canvasCtx!.lineWidth;
                const offsetStartX = startX + lineWidth;
                const offsetStartY = startY + lineWidth;
                const offsetEndX = endX - lineWidth;
                const offsetEndY = endY - lineWidth;
                const width = offsetEndX - offsetStartX;
                const height = offsetEndY - offsetStartY;

                const exportCanvasContext = exportCanvasElement.getContext('2d');

                const imageData: any = exportCanvasContext?.getImageData(
                    offsetStartX,
                    offsetStartY,
                    width,
                    height
                );

                tempCanvasElement.width = width;
                tempCanvasElement.height = height;

                document.body.appendChild(tempCanvasElement);

                const tempCanvasContext = tempCanvasElement.getContext('2d');

                tempCanvasContext?.putImageData(imageData, 0, 0);
                const exportImageData3 = tempCanvasElement.toDataURL();
              
                exportImageData2 = exportImageData3
                setHref(exportImageData2)

                document.body.removeChild(tempCanvasElement);
                snippingBar.style.display = 'flex';
            })
            .catch((err) => {
                console.log(err);
            });
    }


     useEffect(() => {
         if (href) {
         const base64 = href.split('base64,')[1]
             const imageBlob = dataURItoBlob(base64);
             // const blobUrl = URL.createObjectURL(href)
            const imageFile = new File([imageBlob], `${fileName ? fileName : 'Screenshot'}.png`, { type: 'image/png' });

              setFile([imageFile]) 
         }
     }, [href, fileName])

    const dataURItoBlob = (dataURI: any) => {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/png' });
        return blob;
    }

    const getTransparentSquareList = async (
        startX: number,
        startY: number,
        endX: number,
        endY: number,
        canvasWidth: number,
        canvasHeight: number
    ) => {
        /**
                * Bu fonksiyon 8 tane kare üretiyor.
                * X olarak gösterdiğimiz alan fare ile çizilen alan. [startX, startY] [endX, endY] arasında kalan kare.
                * Bu kareye göre diğer 8 kareyi bu şekilde üretiyoruz.
                *  |1|2|3|
                *  |4|X|5|
                *  |6|7|8|
                */
        if (endY < startY) {
            const tempY = startY;
            startY = endY;
            endY = tempY;
        }
        if (endX < startX) {
            const tempX = startX;
            startX = endX;
            endX = tempX;
        }
        const squares: number[][] = [];
        squares.push([0, 0, startX, startY]);
        squares.push([startX, 0, endX - startX, startY - 0]);
        squares.push([endX, 0, canvasWidth - endX, startY - 0]);
        squares.push([0, startY, startX - 0, endY - startY]);
        squares.push([endX, startY, canvasWidth - endX, endY - startY]);
        squares.push([0, endY, startX - 0, canvasHeight - endY]);
        squares.push([startX, endY, endX - startX, canvasHeight - endY]);
        squares.push([endX, endY, canvasWidth - endX, canvasHeight - endY]);
        return squares;
    }

    const onCloseScreenShot = () => {
        dispatch(setButtonState(ButtonState.NONE))
    }
    const handleCloseScreenShot = (e: any) => {

        if (e) {
            dispatch(setScreenshotButton(false))
            /* setFileName('')
            onCloseScreenShot(); */
             window.location.reload() 
        }

    }
    const handleScreenShot = (e: any) => {

        if (e) {
            dispatch(setFeatureAttachments({ attachments: [...featureAttachments.attachments, href] }));
        }

    }
  
    
    
/*      useEffect(() => {
     if (featureAttachments?.attachments && featureAttachments[0]?.attachments.length > 0) {
            console.log(featureAttachments.attachments);
            const o = featureAttachments[0].attachments.map((a: any) => { return a })
            setaaa(o[0])
             // console.log(o[0]);

         }
     }, [featureAttachments]) */

    return (
        <>

            <div className="back-drop">
                <canvas
                    className="canvas"
                    ref={canvasElement}
                    onMouseUp={(e: any) => mouseUp(e)}
                    onMouseDown={(e: any) => mouseDown(e)}
                />

                <div id='snipping-bar' className='snipping-bar'>
                    <InputText className='input' value={fileName} onChange={(e) => setFileName(e.target.value)} />
                    {fields.some((field: any) => field.type === 'Attachment') ? <a onClick={() => {
                        const newAttachments = [...(featureAttachments?.attachments || []), href];
                        dispatch(setFeatureAttachments({ attachments: newAttachments }));
                        // fileNames: [...featureAttachments.fileNames, fileName !== '' ? fileName : 'screenshot.png'] 
                      
                        dispatch(setScreenshotButton(false))
                    }}><Button className='button' icon='pi pi-upload' /></a> : <Button className='button' icon='pi pi-upload' onClick={(e) => handleScreenShot(e)} /> }
                    <a href={href} download={fileName.length > 0 ? fileName + '.jpeg' : 'screenshot.png'} target='_blank' onClick={(e) => handleCloseScreenShot(e)}><Button className='button' icon='pi pi-download' /></a>
                    <Button icon='pi pi-times' onClick={(e) => handleCloseScreenShot(e)} />
                </div>
            </div >
        </>

    )
}

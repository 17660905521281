

import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env

const getToken = () => {
    return localStorage.getItem('token')
}

async function getServices() {
    return axios.get(REACT_APP_BASE_URL + `rest/v1/services`, { params: { token: getToken() } })
}
async function getServiceById(id:any) {
    return axios.get(REACT_APP_BASE_URL + `rest/v1/services/${id}`, { params: { token: getToken() } })
}
export const ServiceRestApi = {
    getServices,
    getServiceById
}


export enum ButtonState {
    // LAYERS = 'LAYERS',
    POINT = 'POINT',
    LINESTRING = 'LINESTRING',
    POLYGON = 'POLYGON',
    HOLE = 'HOLE',
    CLEAR = 'CLEAR',
    INFO = 'INFO',
    TABLE = 'TABLE',
    ADD = 'ADD',
    EDIT = 'EDIT',
    VERTEX = 'VERTEX',
    DELETE = 'DELETE',
    PANORAMA = 'PANORAMA',
    LOCATION = 'LOCATION',
    LANGUAGE = 'LANGUAGE',
    HELP = 'HELP',
    TOUR = 'TOUR',
    FULLSCREEN = 'FULLSCREEN',
    SPLIT = 'SPLIT',
    MOVE = 'MOVE',
    PARALLEL = 'PARALLEL',
    ROAD_PROFILE = 'ROAD_PROFILE',
    NONE = 'NONE',
    IMPORT = 'IMPORT',
    PRINT = 'PRINT',
    EXPORT = 'EXPORT',
    SELECTION = 'SELECTION',
}
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    allFields: [],
    domainFields: []
}

export const domainSlice = createSlice({
    name: 'basemap',
    initialState,
    reducers: {
        setAllFields: (state: any, action) => {
            state.allFields = action.payload;
        },
        setDomainFields: (state: any, action) => {
            state.domainFields = action.payload;
        },
    },
})

export const { setAllFields, setDomainFields } = domainSlice.actions

export default domainSlice.reducer
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  legendVisibility: false,
  mapState: "COMPLETE",
};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setLegendVisibility: (state: any, action) => {
      state.legendVisibility = action.payload;
    },
    setMapState: (state: any, action) => {
      state.mapState = action.payload;
    },
  },
});

export const { setLegendVisibility, setMapState } = mapSlice.actions;

export default mapSlice.reducer;

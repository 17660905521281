
import KML from "ol/format/KML";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import { Extent } from "ol/extent";
import GeoJSON from 'ol/format/GeoJSON';
import GML2 from 'ol/format/GML2';
import * as ShapeFile from 'shapefile';
import { useAppSelector } from "../../../../../state/hooks";
import { useContext, useEffect, useState } from "react";
import { MapContext } from "../MapContext";
import { setImportCompleted, setShowImport } from "../../../../../state/features/importSlice";
import { useDispatch } from "react-redux";
import { setButtonState, setImportButton } from "../../../../../state/features/buttonStateSlice";
import { ButtonState } from "../../../../shared/button-state/ButtonState";
import Fill from "ol/style/Fill";
import CircleStyle from "ol/style/Circle";
import axios from 'axios';
import { setOfflines } from "../../../../../state/features/layerSlice";

type FileResponseType = 'ArrayBuffer' | 'Text';
export const OfflineLayer = () => {
    const dispatch = useDispatch();

    const importedFile = useAppSelector(state => state.import.importedFile);
    const importedEpsg = useAppSelector(state => state.import.importedEpsg);
    const _geojson = new GeoJSON();
    const _gml = new GML2();
    const [features, setFeatures] = useState<any>(undefined);
    const olMap = useContext(MapContext);

    const importedFileVisibility = useAppSelector(state => state.import.importedFileVisibility);
    const offlines = useAppSelector(state => state.layer.offlines);
    const importLayerGroup = useAppSelector((state) => state.import.importLayerGroup);
    const importGroupName = importLayerGroup !== "" ? importLayerGroup.children[0].key : "";
    const enabledGroupAlias = useAppSelector((state) => state.layer.enabledGroupAlias);
    const [vector, setVector] = useState<any>(null);
    
    useEffect(() => {
        let offlineService :any = offlines.find((el:any) => el.label === enabledGroupAlias)
        if (offlineService) {
            const changeFeatures = offlineService.children[0].children[0].features
            const changeVector = offlineService.children[0].children[0].vector
            if (changeVector && changeFeatures) {
                if (!importedFileVisibility) {
                    changeVector.setVisible(false);
                } else {
                    changeVector.setVisible(true);
                }
            }
        }
    }, [importedFileVisibility])

   useEffect(()=>{
        if(features && features.length){
            const otherServices = offlines.filter((el:any) => el.label !== importGroupName)
            const offlineService = offlines.find((el:any) => el.label === importGroupName)
            const offline = JSON.parse(JSON.stringify(offlineService)); //{...offlineService}//
            offline.children[0].children[0].features = features
            offline.children[0].children[0].vector = vector
            dispatch(setOfflines([...otherServices, offline]));
        }
    }, [features])

    useEffect(() => {
        
        if (importedFile.length) {
            const fileName = importedFile[0].name;
            const filenameData = fileName.split('.');
            const filePrefixName = filenameData[0];
            let fileType = filenameData[1];
            let fileResponseType: FileResponseType = 'ArrayBuffer';
            if (fileType === undefined) {
              console.log('File type does not exist');
            }
            else{
                fileType = fileType.toLowerCase();
                fileResponseType = 'Text';
                switch (fileType) {
                    case 'kml':
                        importedFile?.map((file: any) => {
                            file.text().then((kml: any) => {
                            const kmlReader = new KML()
                            const features: any = kmlReader.readFeatures(kml,
                                {
                                    dataProjection: importedEpsg,
                                    featureProjection: 'EPSG:3857',
                                });
                            // for (const feature of features) {
                            //     feature.getGeometry()?.transform('EPSG:4326', 'EPSG:3857');
                            // }
                            const vector = new VectorLayer({
                                source: new VectorSource(),
                                zIndex: 10000000000,
                                style: new Style({
                                  stroke: new Stroke({
                                    color: '#888',
                                    width: 3
                                  })
                                }),
                                properties:{
                                    alias: importGroupName
                                }
                              });
                            setVector(vector)
                            setFeatures(features);
                            vector.getSource()?.addFeatures(features)
                            olMap?.addLayer(vector);
                            olMap?.getView().fit(vector.getSource()?.getExtent() as Extent, { padding: [10, 10, 10, 10] })
                            });
                        })
                    break;
                    case 'gml':
                        getFileAsAText(importedFile[0], fileResponseType).then((result:any)=>{
                            const features = _gml.readFeatures(
                                result.response,
                                {
                                    dataProjection: importedEpsg,
                                    featureProjection: 'EPSG:3857',
                                }
                              );
                            if(features.length){
                                const vector = new VectorLayer({
                                    source: new VectorSource(),
                                    zIndex: 10000000000,  
                                    style: new Style({
                                    stroke: new Stroke({
                                        color: '#888',
                                        width: 3
                                    })
                                    }),
                                    properties:{
                                        alias: importGroupName
                                    }
                                });
                                vector.getSource()?.addFeatures(features)
                                olMap?.addLayer(vector);
                                olMap?.getView().fit(vector.getSource()?.getExtent() as Extent, { padding: [10, 10, 10, 10] })
                                setVector(vector)
                                setFeatures(features)
                                
                            }else{
                                /*setTimeout(() => {
                                    dispatch(setRemoveGroupAlias(importGroupName))
                                    dispatch(setServices([...services.filter((el:any)=>el.alias!==importGroupName)]));
                                }, 0);*/
                            }
                        })
                    break;
                    case 'json':
                    case 'geojson':
                        getFileAsAText(importedFile[0], fileResponseType).then((result:any)=>{
                            const features = _geojson.readFeatures(
                                result.response,
                                {
                                    dataProjection: importedEpsg,
                                    featureProjection: 'EPSG:3857',
                                }
                              );
                            const vector = new VectorLayer({
                                source: new VectorSource(),
                                zIndex: 10000000000,
                                style: new Style({
                                    fill: new Fill({
                                      color: '#64b5f640',
                                    }),
                                    stroke: new Stroke({
                                      color: '#3ea1f1',
                                      width: 3,
                                    }),
                                    image: new CircleStyle({
                                      radius: 5,
                                      fill: new Fill({
                                        color: '#3ea1f1',
                                      }),
                                    }),
                                  }),
                                properties:{
                                    alias: importGroupName
                                }
                              });
                            vector.getSource()?.addFeatures(features)
                            olMap?.addLayer(vector);
                            olMap?.getView().fit(vector.getSource()?.getExtent() as Extent, { padding: [10, 10, 10, 10] })
                            setVector(vector)
                            setFeatures(features)
                        })
                    break;
                    case 'shp':
                    case 'shx':
                    case 'prj':
                    case 'dbf':
                    case 'cst':
                        const files:File[] = importedFile;
                        const textRequestArray = [];
                        for (const file of files) {
                            textRequestArray.push(getFileAsAText(file, 'ArrayBuffer'));
                        }
                        Promise.all(textRequestArray).then((resultArray:any) => {
                            const alreadyAddedShapeFileNameList = [];
                          //  const featurePromiseList: any = [];
                            const features:any = [];
                            for (const item of resultArray) {
                                const fileName: string = item.file.name;
                                const filenameData = fileName.split('.');
                                const filePrefixName = filenameData[0];
                                let fileType = filenameData[1];
                                if (fileType === undefined) {
                                    console.log('File type does not exist');
                                    continue;
                                }
                                fileType = fileType.toLowerCase();

                                const indexOfOldFile = alreadyAddedShapeFileNameList.indexOf(filePrefixName);
                                if (indexOfOldFile === -1) {
                                    alreadyAddedShapeFileNameList.push(filePrefixName);
                                    // const shapeFiles = resultArray.filter((e:any) => e.file.name.split('.')[0] === filePrefixName);
                                    // const shpItem = shapeFiles.find((e:any) => e.file.name.split('.')[1].toLowerCase() === 'shp');
                                    // const dbfItem = shapeFiles.find((e:any) => e.file.name.split('.')[1].toLowerCase() === 'dbf');
                                    const shapeFile = resultArray.filter(
                                        (e:any) => e.file.name.split('.')[0] === filePrefixName
                                      );
                                      const shpItem = shapeFile.find(
                                        (e:any) => e.file.name.split('.')[1].toLowerCase() === 'shp'
                                      );
                                      const dbfItem = shapeFile.find(
                                        (e:any) => e.file.name.split('.')[1].toLowerCase() === 'dbf'
                                      );
                                      const shxItem = shapeFile.find(
                                        (e:any) => e.file.name.split('.')[1].toLowerCase() === 'shx'
                                      );

                                      const shapeFiles = new FormData();
                                      shapeFiles.append('files', shpItem.file);
                                      shapeFiles.append('files', dbfItem.file);
                                      shapeFiles.append('files', shxItem.file);

                                    
                                    if (shpItem && dbfItem) {

                                        const url = process.env.REACT_APP_BASE_URL + 'rest/v1/util/shpToGeojson'



                                        const shapeFeaturesPromise = async () => {

                                            
                                            const source1 = await axios.post(url, shapeFiles)
                                                             
                                            const source2 = await ShapeFile.open(
                                                shpItem.response,
                                                dbfItem.response,
                                                { encoding: 'utf-8' }
                                              );
                          
                                              const source = source1 ? source1.data : source2;

                                              if (!source1) {
                                                const features :any = [];
                                                
                                                return features;
                                              } else {
                                                const features :any = [];
                                                const geojsonInstance = new GeoJSON();
                                                source.data.features.forEach((element:any) => {
                                                  features.push(
                                                    geojsonInstance.readFeature(
                                                      element,
                                                      {
                                                        dataProjection: importedEpsg,
                                                        featureProjection: 'EPSG:3857',
                                                      }
                                                    )
                                                  );
                                                });
                                                return features;
                                              }
                                        }
                                        shapeFeaturesPromise().then((features:any)=>{
                                            const vector = new VectorLayer({
                                                source: new VectorSource(),
                                                zIndex: 10000000000,
                                                style: new Style({
                                                  stroke: new Stroke({
                                                    color: '#888',
                                                    width: 3
                                                  })
                                                }),
                                                properties:{
                                                    alias: importGroupName
                                                }
                                              });
                                            vector.getSource()?.addFeatures(features)
                                            olMap?.addLayer(vector);
                                            olMap?.getView().fit(vector.getSource()?.getExtent() as Extent, { padding: [10, 10, 10, 10] })
                                            setVector(vector)
                                            setFeatures(features)
                                        })
                                    // featurePromiseList.push(shapeFeaturesPromise());
                                        // Shapefile.open(shpItem.response, dbfItem.response).then((source:any)=>{
                                        // console.log("source:",source);

                                        //     let isThereASomeOtherFeatures = true;
                                        //     while (isThereASomeOtherFeatures) {
                                        //         source.read().then((result:any)=>{
                                        //             if (result.done) {
                                        //                 isThereASomeOtherFeatures = false;
                                        //             } else {
                                        //                 features.push(_geojson.readFeature(result.value, readFeatureOptions));
                                        //                 console.log("current features  : ",features);
                                        //             }
                                        //         });
                                        //     }
                                        // })
                                    //   featurePromiseList.push({ promise: shapeFeaturesPromise(), layerName: filePrefixName });
                                    } else {
                                        // console.log('shp dbf bulunamadı');
                                    }
                                }
                            }
                        })
                        break;
                       
                }
                setTimeout(() => {
                    // dispatch(setShowImport(false)) 
                    dispatch(setImportButton(false))
                    dispatch(setButtonState(ButtonState.NONE))
                    dispatch(setImportCompleted(true))
                }, 10);
                /**
                 * 
                 * YÜKLENDİ MESAJI
                 * 
                 */
            }

        }
      }, [importedFile])
      
      const getFileAsAText = (file:any, type: FileResponseType) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (loadEvent) => {
            resolve({ response: reader.result, file });
          };
          reader.onerror = (error) => {
            reject(error);
          };
          if (type === 'ArrayBuffer') {
            reader.readAsArrayBuffer(file);
          } else if (type === 'Text') {
            reader.readAsText(file);
          }
        });
      };
    return (   
        <>
        </> 
    )
}
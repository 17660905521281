import axios from 'axios';

const {REACT_APP_BASE_URL} = process.env

async function customize() {
    return axios.get(REACT_APP_BASE_URL + 'rest/v1/customize');
}

export const CustomizeRestApi = {
    customize
}


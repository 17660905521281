import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { basemapSlice } from "./features/basemapSlice";
import { buttonStateSlice } from "./features/buttonStateSlice";
import { customizeSlice } from "./features/customizeSlice";
import { loginSlice } from "./features/loginSlice";
import { panoramaSlice } from "./features/panoramaSlice";
import { layerSlice } from "./features/layerSlice";
import { mapSlice } from "./features/mapSlice";
import { selectedFeatureSlice } from "./features/selectedFeatureSlice";
import { domainSlice } from "./features/domainSlice";
import { drawStateSlice } from "./features/drawStateSlice";
import { coordinatesStateSlice } from "./features/coordinatesSlice";
import { languageSlice } from "./features/languageSlice";
import { tableSlice } from "./features/tableSlice";
import { infoSlice } from "./features/infoSlice";
import { digitizationSlice } from "./features/digitizationSlice";
import { importSlice } from "./features/importSlice";
import { exportSlice } from "./features/exportSlice";
import { urlSlice } from "./features/urlSlice";
import { printSlice } from "./features/printSlice";
import { roadProfileSlice } from "./features/roadProfile";
import { loadingSlice } from "./features/loadingSlice";
import { styleControlSlice } from "./features/styleControlSlice"

export const store = configureStore({
  reducer: {
    customize: customizeSlice.reducer,
    login: loginSlice.reducer,
    basemaps: basemapSlice.reducer,
    buttonState: buttonStateSlice.reducer,
    panorama: panoramaSlice.reducer,
    layer: layerSlice.reducer,
    map: mapSlice.reducer,
    selectedFeature: selectedFeatureSlice.reducer,
    domain: domainSlice.reducer,
    drawState: drawStateSlice.reducer,
    coordinates: coordinatesStateSlice.reducer,
    language: languageSlice.reducer,
    table: tableSlice.reducer,
    info: infoSlice.reducer,
    loading: loadingSlice.reducer,
    digitization: digitizationSlice.reducer,
    import: importSlice.reducer,
    export: exportSlice.reducer,
    url: urlSlice.reducer,
    print: printSlice.reducer,
    roadProfile: roadProfileSlice.reducer,
    styleControl: styleControlSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, /// redux-persist serializable check deactivated.
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import { useContext, useEffect, useState } from 'react'
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import { MapContext } from '../../../map-container/map/MapContext';
import { Mode, TransactWfs } from '../TransactWfs';
import Modify from 'ol/interaction/Modify'
import { useDispatch } from 'react-redux';
import Collection from 'ol/Collection';
import MultiPoint from 'ol/geom/MultiPoint';
import Draw from 'ol/interaction/Draw';
import { never } from 'ol/events/condition';
import { useAppSelector } from '../../../../../state/hooks';
import { setCancelDigiActivate, setDigiPopupVisibility, setFrom, setGeomEditStatus } from '../../../../../state/features/digitizationSlice';
import { setButtonState } from '../../../../../state/features/buttonStateSlice';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import { setSelectedFeature } from '../../../../../state/features/selectedFeatureSlice';
import { DigiPopup } from '../digi-popup/DigiPopup';
import { TransactEdit } from '../TransactEdit';
import Snap from 'ol/interaction/Snap';
import { useTranslation } from 'react-i18next';
import MapBrowserEventType from 'ol/MapBrowserEventType';
import { LineString  } from 'ol/geom';
interface ModifyEndEvent {
    features: any;
    mapBrowserEvent: {
      coordinate: number[];
      originalEvent: PointerEvent;
      [key: string]: any;
    };
    target: Modify;
    type: string;
  }
const {
    POINTERUP,
} = MapBrowserEventType;
  
export const DigiGeomEdit = () => {
    const map = useContext(MapContext);
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [source, setSource] = useState<VectorSource<any>>(new VectorSource())
    const [vectorLayer, setVectorLayer] = useState<VectorLayer<any>>(new VectorLayer())
    const [olFeatureForEdit, setolFeatureForEdit] = useState<any>(undefined)
    const [geojsonFeature, setGeojsonFeature] = useState<any>(undefined)
    const selectedFeature: any = useAppSelector(state => state.selectedFeature.feature)
    const popupSaveStatus: any = useAppSelector(state => state.digitization.popupSaveStatus)
    const geomEditStatus: any = useAppSelector(state => state.digitization.geomEditStatus)
    const cancelDigiActivate = useAppSelector((state) => state.digitization.cancelDigiActivate)
    const panoramaTransactStatus = useAppSelector(state => state.digitization.panoramaTransactStatus)
    const buttonState: any = useAppSelector(state => state.buttonState.buttonState)
    const digiPopupVisibility = useAppSelector((state) => state.digitization.digiPopupVisibility);
    useEffect(() => {        
        map.updateSize();
    }, [])

    const style = new Style({
        fill: new Fill({
            color: 'rgb(165, 33, 37, 0.44)',
        }),
        stroke: new Stroke({
            color: '#00FFFF',
            width: 6,
        }),
        image: new CircleStyle({
            radius: 5,
            fill: new Fill({
                color: '#38EEF5',
            }),
        }),
    })

    const styles = [
        new Style({
            fill: new Fill({
                color: 'rgb(165, 33, 37, 0.44)',
            }),
            stroke: new Stroke({
                color: '#00FFFF',
                width: 6,
            }),
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: '#38EEF5',
                }),
            }),
        }),
        new Style({
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: 'orange',
                }),
            }),
            geometry: (olFeatureForEdit: any) => {
                // return the coordinates of the first ring of the polygon
                const point = new MultiPoint([])
                point.appendPoint(olFeatureForEdit.getGeometry())
                return point;
            },
        }),
    ];

    useEffect(() => {
        if (selectedFeature && selectedFeature.length > 0) {
            if (selectedFeature[0].geometry) {
                const vectorSource = new VectorSource();

                const vector = new VectorLayer({
                    source: vectorSource,
                    zIndex: 10000000000000000,
                    style: style
                });
                map.addLayer(vector);
                setVectorLayer(vector)
                const geoJson = new GeoJSON()
                const feature: any = geoJson.readFeature(selectedFeature[0])
                vectorSource.addFeature(feature);
                setSource(vectorSource)
                if (feature && feature.getGeometry()) {
                    setolFeatureForEdit(feature);
                    setGeojsonFeature(selectedFeature[0]);
                }
                return () => {
                    map.removeLayer(vector)
                    // console.log("ffs    ");
                    // source.clear(); 
                    
                    // const ffs: any = source.getFeatures();
                    // source.removeFeature(ffs[ffs.length-1]);
                }
            }
        }
    }, [selectedFeature[0]?.id])

    const modifyEnd = (event: ModifyEndEvent)=>{
        const feature:any = event.features.item(0);
        const geom = feature.getGeometry();
        const geomType = geom.getType();
        const coord = geom.getCoordinates();
        try {
            switch (geomType) {
                case 'MultiLineString':
                    coord[0].map((item:any, index:any) => {
                      if (!item[2]) {
                        const lineToNext = new LineString([item, coord[0][index + 1]]);
                        const lineToPre = new LineString([item, coord[0][index - 1]]);
                        const distanceofNext = Math.round(lineToNext.getLength() * 100) / 100;
                        const distanceofPre = Math.round(lineToPre.getLength() * 100) / 100;
                        const nextZ = coord[0][index + 1][2];
                        const previousZ = coord[0][index - 1][2];
                        const addedZ = ((previousZ * distanceofNext) / (distanceofNext + distanceofPre)) +
                          ((nextZ * distanceofPre) / (distanceofNext + distanceofPre));
                        item.pop();
                        item.push(addedZ);
                      }
                    });
                  feature.getGeometry().setCoordinates(coord);
                  break;
                case 'MultiPolygon':
                    coord[0][0].map((item:any, index:any) => {
                      if (!item[2]) {
                        const lineToNext = new LineString([item, coord[0][0][index + 1]]);
                        const lineToPre = new LineString([item, coord[0][0][index - 1]]);
                        const distanceofNext = Math.round(lineToNext.getLength() * 100) / 100;
                        const distanceofPre = Math.round(lineToPre.getLength() * 100) / 100;
                        const nextZ = coord[0][0][index + 1][2];
                        const previousZ = coord[0][0][index - 1][2];
                        const addedZ = ((previousZ * distanceofNext) / (distanceofNext + distanceofPre)) +
                          ((nextZ * distanceofPre) / (distanceofNext + distanceofPre));
                        item.pop();
                        item.push(addedZ);
                      }
                    });
                  feature.getGeometry().setCoordinates(coord);
                break;
                default:
                break;
            }
        } catch (error) {
        }
    }
    useEffect(() => {
        
        if (olFeatureForEdit) {
                let draw = new Draw({
                    source: source,
                    type: 'Polygon',
                    style: new Style({
                        fill: new Fill({
                            color: 'rgb(165, 33, 37, 0.44)',
                        }),
                        stroke: new Stroke({
                            color: '#A52125',
                            width: 2,
                        }),
                        image: new CircleStyle({
                            radius: 5,
                            fill: new Fill({
                                color: '#A52125',
                            }),
                        }),
                    }),
                    finishCondition: never,
                    condition: function (e: any) {
                        //sadece solla çizim yapıyor ve sağla bitiriyor
                        if (e.originalEvent.buttons === 1) {
                            return true;
                        } else if (e.originalEvent.buttons === 2) {
                            draw.finishDrawing();
                            return false;
                        }
                        else {
                            return false;
                        }
                    },
                });
                vectorLayer.setStyle(styles)
                const modify = new Modify({
                    condition: () => {
                        return true;
                      },
                      deleteCondition(mapBrowserEvent: any): boolean {
                        return mapBrowserEvent.originalEvent.shiftKey && mapBrowserEvent.type === POINTERUP;
                      },
                      insertVertexCondition(mapBrowserEvent: any): boolean {
                        return mapBrowserEvent.originalEvent.ctrlKey;
                      },
                      features: buttonState === ButtonState.VERTEX ? new Collection([olFeatureForEdit]) : new Collection([]) 
                })
                modify.on('modifyend', modifyEnd);
                if(buttonState === ButtonState.VERTEX){
                    map.addInteraction(modify);
                }
           
                //  const snap = new Snap({
                //     source: source,
                // })
                // const intrcts : any= (window as any).olMap.getInteractions().getArray().filter((interaction:any)=>interaction instanceof Snap)
                // const control : any= intrcts.find((interaction:any)=>interaction.getProperties().name === selectedLayer.name)
                // // if(!control){
                //     // snap.setProperties({"name":selectedLayer.name})
                //     map.addInteraction(snap)
                // // }   
 
                (window as any).olMap.getLayers().getArray()
                    .filter((layer2: any) => layer2.getVisible())
                    .map((layer: any) => {
                        const wms: any = layer;
                        const _source = wms.getSource()
                        if (_source instanceof VectorSource) {
                            const snap = new Snap({
                                source: _source
                            });
                            map.addInteraction(snap);
                        }
                    })
                const rightClickHandler = (event: any) => {
                    if((window as any).buttonState !== ButtonState.EDIT){
                        event.preventDefault();
                        map.removeInteraction(modify);
                        dispatch(setGeomEditStatus(true))
                        dispatch(setDigiPopupVisibility(true))
                        dispatch(setFrom("Map"))
                    }
                    // bi defa yaptiktan sonra kaldiriyoruz event i
                }
                // sag tiklayinca bitirmesi icin
                document.addEventListener('contextmenu', rightClickHandler);
                (window as any).customRightClick = rightClickHandler; /** ? */
            
        }
        return () => {
            const intrcts = (window as any).olMap.getInteractions().getArray()
            intrcts.forEach((interaction: any) => {
                if (interaction instanceof Snap || interaction instanceof Modify) {
                    map.removeInteraction(interaction)
                    // console.log("modifyInteraction çıkış  ");
                }
            });
            // map.removeInteraction(modifyInteraction);
            document.removeEventListener('contextmenu', (window as any).customRightClick);
        }

    }, [olFeatureForEdit])

    useEffect(() => {
        if (cancelDigiActivate) {
            map.removeLayer(vectorLayer)
            // const ffs: any = source.getFeatures();
            // source.removeFeature(ffs[ffs.length-1]);
            source.clear();
            dispatch(setCancelDigiActivate(false));
            // dispatch(setGeomEditStatus(false))
            // dispatch(setButtonState(ButtonState.NONE))
            // source.clear();
        }
    }, [cancelDigiActivate])

    return (
        <>
            {digiPopupVisibility && buttonState === ButtonState.VERTEX && <DigiPopup title='Veri Düzenle' content={`${t('CONFIRMATION.Save')}`} from='Map' mode={Mode.UPDATE} />}
            {!panoramaTransactStatus && geomEditStatus && popupSaveStatus && buttonState === ButtonState.VERTEX &&

                // <TransactWfs
                //  coordinates={olFeatureForEdit.getGeometry().getCoordinates()}
                //  feature={geojsonFeature} mode={Mode.UPDATE}
                //  type={geojsonFeature.geometry.type}
                //  source={source}
                // />

                <TransactEdit
                    coordinates={olFeatureForEdit.getGeometry().getCoordinates()}
                    feature={olFeatureForEdit} mode={Mode.UPDATE}
                    type={geojsonFeature.geometry.type}
                    source={source}
                />}
        </>
    )
}

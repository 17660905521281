import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isFullScreen: false,
    isPanoMiniScreen: false,
    isMultiOpen: false,
    layerName: '',
    angle: {
        rotationX: 0.0,
        rotationY: 0.0,
        fov: 0.8333333333333334
    },
    angle2: {
        rotationX: 0.0,
        rotationY: 0.0,
        fov: 0.8333333333333334
    },
    angle3: {
        rotationX: 0.0,
        rotationY: 0.0,
        fov: 0.8333333333333334
    },
    info: {
        shootingDate: '2022',
        label: 'GIS'
    },
    pointerCoordinate: [28.982648, 41.035709],
    mapCoordinate: [28.982648, 41.035709],
    panoramaCoordinate: { lat: 41.035680228, lon: 28.982637452 },
    panoramaCoordinateForPano2: { lat: 41.03423642, lon: 28.979048085 },
    panoramaCoordinateForPano3: { lat: 41.03419338, lon: 28.978942413 },
    projection: 'EPSG:4326',
    type: 'Floor',
    wktGdhForParallel:null,
    cursorCoordinate:[0, 0],
    cursorVisible: false,
    drawUids : [],
    panoByPanogps : false,
    event: null,
    text:null,
    gdh:null,
    childKey:0,
    eventStatusList:[]
}

export const panoramaSlice = createSlice({
    name: 'panorama',
    initialState,
    reducers: {
        //tam ekran ve splitter arasında geçiş yapmak için
        setIsFullScreen: (state: any, action) => {
            state.isFullScreen = action.payload
        },
        //mini ekran ve full ekran arasındaki map ve pano değişimi için
        setIsPanoMiniScreen: (state: any, action) => {
            state.isPanoMiniScreen = action.payload
        },
        setIsMultiOpen: (state: any, action) => {
            state.isMultiOpen = action.payload
        },
        setViewAngle: (state: any, action) => {
            state.angle = action.payload
        },
        setViewAngle2: (state: any, action) => {
            state.angle2 = action.payload
        },
        setViewAngle3: (state: any, action) => {
            state.angle3 = action.payload
        },
        setPanoramaInfo: (state: any, action) => {
            state.info = action.payload
        },
        setMapCoordinate: (state: any, action) => {
            state.mapCoordinate = action.payload
        },
        setPointerCoordinate: (state: any, action) => {
            state.pointerCoordinate = action.payload
        },
        setPanoramaCoordinate: (state: any, action) => {
            state.panoramaCoordinate = action.payload
        },
        setCoordinateForPano2: (state: any, action) => {
            state.panoramaCoordinateForPano2 = action.payload
        },
        setCoordinateForPano3: (state: any, action) => {
            state.panoramaCoordinateForPano3 = action.payload
        },
        setSelectedProjection: (state: any, action) => {
            state.projection = action.payload
        },
        setPanoramaType: (state: any, action) => {
            state.type = action.payload
        },
        setWktGdhForParallel: (state: any, action) => {
            state.wktGdhForParallel = action.payload
        },
        setCursorCoordinate: (state: any, action) => {
            state.cursorCoordinate = action.payload
        },
        setCursorVisible: (state: any, action) => {
            state.cursorVisible = action.payload
        },
        setDrawUids: (state: any, action) => {
            state.drawUids = action.payload
        },
        setPanoByPanogps: (state: any, action) => {
            state.panoByPanogps = action.payload
        },
        setEvent: (state: any, action) => {
            state.event = action.payload
        },
        setText: (state: any, action) => {
            state.text = action.payload
        },
        setGdh: (state: any, action) => {
            state.gdh = action.payload
        },
        setChildKey: (state: any, action) => {
            state.childKey = action.payload
        },
        setEventStatusList: (state: any, action) => {
            state.eventStatusList = action.payload
        }
        

        
    }
})

export const { setIsFullScreen,setEventStatusList, setChildKey, setText, setGdh, setEvent, setIsPanoMiniScreen, setIsMultiOpen, setMapCoordinate, setPanoramaInfo, setPanoramaCoordinate, setCoordinateForPano2, setCoordinateForPano3, setPointerCoordinate, setSelectedProjection, setViewAngle, setViewAngle2, setViewAngle3, setPanoramaType, setWktGdhForParallel, setCursorCoordinate, setCursorVisible, setDrawUids, setPanoByPanogps } = panoramaSlice.actions;

export default panoramaSlice.reducer;
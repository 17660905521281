import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    loginStatus: '',
    showToast: false,
    user: {
        id: 0,
        firstname: '',
        lastname: '',
        username: '',
        email: '',
        phone: '',
        isadmin: true,
        isactive: true,
        logoff : false
    },
}

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setLoginStatus: (state: any, action) => {
            state.loginStatus = action.payload;
        },
        setToastVisibility: (state: any, action) => {
            state.showToast = action.payload;
        },
        setUserDetails: (state: any, action) => {
            state.user = action.payload;
        },
        setLogOff: (state: any, action) => {
            state.logoff = action.payload;
        }
    }
})

export const { setLoginStatus, setToastVisibility, setUserDetails, setLogOff } = loginSlice.actions

export default loginSlice.reducer
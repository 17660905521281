import { createSlice } from '@reduxjs/toolkit'

interface ExportState {
    showExport: boolean
    layerNodes: [],
    exportedEpsg:string,
    exportCompleted:boolean,
    layerAlias : string,
    url: string,
    workspace: string
}

const initialState: ExportState = {
    showExport: false,
    layerNodes: [],
    exportedEpsg:"",
    exportCompleted:false,
    layerAlias : "",
    url: "",
    workspace: ""
}

export const exportSlice = createSlice({
    name: 'export',
    initialState,
    reducers: {
        setShowExport: (state: any, action) => {
            state.showExport = action.payload;
        },
        setExportLayerGroup: (state: any, action) => {
            state.exportLayerGroup = action.payload;
        },
        setLayerNodes: (state: any, action) => {
            state.layerNodes = action.payload;
        },
        setExportName: (state: any, action) => {
            state.exportedGroupAlias = action.payload;
        },
        setExportedEpsg: (state: any, action) => {
            state.exportedEpsg = action.payload;
        },
        setExportCompleted: (state: any, action) => {
            state.exportCompleted = action.payload;
        },
        setExportLayerAlias: (state: any, action) => {
            state.layerAlias = action.payload;
        },
        setExportLayerUrl: (state: any, action) => {
            state.url = action.payload;
        },
        setExportLayerWorkspace: (state: any, action) => {
            state.workspace = action.payload;
        },
        
    }
})

export const { setShowExport,
    setExportLayerUrl, setExportLayerWorkspace,
    setExportLayerGroup, setLayerNodes, setExportName, setExportedEpsg, setExportCompleted, setExportLayerAlias } = exportSlice.actions;

export default exportSlice.reducer;
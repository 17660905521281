import { createSlice } from '@reduxjs/toolkit';
import { DrawStateType } from '../../util/model/drawState';

const initialState: any = {
    mouseMode: null
}

export const roadProfileSlice = createSlice({
    name: 'roadProfile',
    initialState,
    reducers: {
        setMouseMode: (state: any, action) => {
            state.mouseMode = action.payload;
        }
    }
})

export const { setMouseMode } = roadProfileSlice.actions

export default roadProfileSlice.reducer
import { createSlice } from '@reduxjs/toolkit';


//interface duzelt
const initialState: any = {
    value: {
        id: 0,
        title: '',
        url: '',
        icon: '',
        layername: '',
        priority: 0,
        type: 0,
        visible: false
    },
}

export const basemapSlice = createSlice({
    name: 'basemap',
    initialState,
    reducers: {
        setBasemaps: (state: any, action) => {
            state.value = action.payload;
        },
        changeVisibility: (state: any, action) => {
            const { index, visibility } = action.payload as { index: number, visibility: boolean };
            
            state.value.map((basemap: any) => {
                basemap.visible = false
            })
            state.value[index].visible = visibility
        }
    },
})

export const { setBasemaps, changeVisibility } = basemapSlice.actions


export default basemapSlice.reducer
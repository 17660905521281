import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    //feature wkt formatında
    mapCoordinates: { type: '', feature: [] },
    //feature geojson formatında
    panoCoordinates: { type: '', feature: [] },
}

export const coordinatesStateSlice = createSlice({
    name: 'coordinates',
    initialState,
    reducers: {
        setMapCoordinates: (state: any, action) => {
            state.mapCoordinates = action.payload;
        },
        setPanoCoordinates: (state: any, action) => {
            state.panoCoordinates = action.payload;
        },
    },
})

export const { setMapCoordinates, setPanoCoordinates } = coordinatesStateSlice.actions

export default coordinatesStateSlice.reducer
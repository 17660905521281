export enum DrawStateType {
    // Zemin ölçümleri
    POINT = 'POINT',
    LINESTRING = 'LINESTRING',
    POLYGON = 'POLYGON',
    CLEAR = 'CLEAR',
    FINISH = 'FINISH',
    DEFAULT = 'DEFAULT',
    NONE = 'NONE',
    HLINE = "Hline",
    ROAD_LINE = "roadLine",
    GRID = "grid",

    // PC ölçümleri
    PANORAMA_POİNT_PC = 'point',
    PANORAMA_LINE_PC = 'line',
    PANORAMA_HLINE_PC = 'hLine',
    PANORAMA_POLYGON_PC = 'polygon',
    PANORAMA_SQUARE_PC = 'square',
    PANORAMA_TRIANGLE_PC = 'triangle',
}


export enum DrawStatesPC {
  NONE = -1,
  POLYGON_PC_DRAW = 2,
  LINE_PC_DRAW = 1,
  HLINE_PC_DRAW = 3,
  POINT_PC_DRAW = 0,
  SQUARE_PC_DRAW = 4,
  GROUND_FROM_TOP_PC_DRAW = 5,
  SLOPE_PC_DRAW = 7
}

export enum MultiDrawTypes {
  // Multi ölçümleri
  LINE_MULTI = 'multiLine',
  HLINE_MULTI = 'multiHLine',
  POLYGON_MULTI = 'multiPolygon',
  POINT_MULTI = 'multiPoint',
  SQUARE_MULTI = 'multiSquare',
  TRIANGLE_MULTI = 'multiTriangle',

  DIGI_LINE_MULTI = 'digiMultiLine',
  DIGI_POLYGON_MULTI = 'digiMultiPolygon',
  DIGI_POINT_MULTI = 'digiMultiPoint',
  DIGI_SQUARE_MULTI = 'digiMultiSquare',
  DIGI_TRIANGLE_MULTI = 'digiMultiTriangle',
  CLEAR_MULTI = 'multiClear',
}

export enum ProfileDrawTypes {
  // Profile ölçümleri
  START_PROFILE = 'startProfile',
  STOP_PROFILE = 'stopProfile',
  CLEAR_PROFILE = 'clearProfile',
}

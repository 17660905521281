
import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env

const getToken = () => {
    return localStorage.getItem('token')
}

async function validate() {
    return axios.get(REACT_APP_BASE_URL + 'rest/v1/license/validate');
}

export const ValidateRestApi = {
    validate
}
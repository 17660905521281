import { Dialog } from 'primereact/dialog';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setButtonState } from '../../state/features/buttonStateSlice';
import { ButtonState } from '../shared/button-state/ButtonState';

export const Help = () => {
  const dispatch = useAppDispatch();
  const buttonState = useAppSelector((state) => state.buttonState.buttonState);
  const { REACT_APP_BASE_URL } = process.env
  const pdfUrlEn =
    `${REACT_APP_BASE_URL}assets/Uygulama_UserGuide.pdf`;
  const pdfUrlTr =
    `${REACT_APP_BASE_URL}assets/Uygulama_Kullan%C4%B1mK%C4%B1lavuzu.pdf`;
  const language = useAppSelector((state) => state.language.value);

  return (
    <div>
      {language === 'tr' ? (
        <Dialog
          header="Yardım"
          visible={buttonState === ButtonState.HELP}
          style={{ width: '675px' }}
          onHide={() => dispatch(setButtonState(ButtonState.NONE))}
        >
          <>
            <iframe
              src={pdfUrlTr}
              title="PDF Görüntüleyici"
              width="100%"
              height="600px"
              allow="autoplay"
            >
              Tarayıcınız PDF dökümantasyonunu desteklemiyor. PDF'yi
              görüntülemek için indirin.
            </iframe>
          </>
        </Dialog>
      ) : (
        <Dialog
          header="Help"
          visible={buttonState === ButtonState.HELP}
          style={{ width: '675px' }}
          onHide={() => dispatch(setButtonState(ButtonState.NONE))}
        >
          <>
            <iframe
              src={pdfUrlEn}
              title="PDF Viewer"
              width="100%"
              height="600px"
              allow="autoplay"
            >
              This browser does not support PDFs. Please download the PDF to
              view it.
            </iframe>
          </>
        </Dialog>
      )}
    </div>
  );
};

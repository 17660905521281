import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import CircleStyle from 'ol/style/Circle'
import { Fill, Stroke, Style } from 'ol/style'
import { useContext, useEffect } from 'react'
import { useAppSelector } from '../../../../../state/hooks'
import { MapContext } from '../MapContext'
import { GeoJSON } from 'ol/format';
import { useDispatch } from 'react-redux'
import { setGoToSelection } from '../../../../../state/features/tableSlice'
import { MultiPoint } from 'ol/geom'

export const SelectedFeature = () => {
    const dispatch = useDispatch();
    const map = useContext(MapContext);
    const selectedFeature: any = useAppSelector((state) => state.selectedFeature.feature);
    const goToSelection: any = useAppSelector((state) => state.table.goToSelection);
    const tableExtent: any = useAppSelector((state) => state.table.tableExtent);
    const vertexFeature: any = useAppSelector(state => state.selectedFeature.vertexFeature);
    let feature: any = {}
    let geom: any = {}

    useEffect(() => {
        map.updateSize();
    }, [])

    useEffect(() => {
        const vectorSource = new VectorSource();

        const vector = new VectorLayer({
            source: vectorSource,
            zIndex: 10000000000000000,
            style: new Style({
                fill: new Fill({
                    color: 'transparent',
                }),
                stroke: new Stroke({
                    color: '#00FFFF',
                    width: 6,
                }),
                image: new CircleStyle({
                    radius: 6,
                    fill: new Fill({
                        color: '#00FFFF',
                    }),
                }),
            })
        });

        const styles = [
            new Style({
                fill: new Fill({
                    color: 'rgb(165, 33, 37, 0.44)',
                }),
                stroke: new Stroke({
                    color: '#00FFFF',
                    width: 6,
                }),
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: '#38EEF5',
                    }),
                }),
            }),
            new Style({
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: 'orange',
                    }),
                }),
                geometry: (olFeatureForEdit: any) => {
                    // return the coordinates of the first ring of the polygon
                    const point = new MultiPoint([])
                    point.appendPoint(olFeatureForEdit.getGeometry())
                    return point;
                },
            }),
        ];

        const vectorx = new VectorLayer({
            source: vectorSource,
            zIndex: 10000000000000000,
            style: styles
        });


        map.addLayer(selectedFeature.length > 0 ? vector : vectorx);

        // map.addLayer(vector);

        if (selectedFeature && selectedFeature.length > 0 && selectedFeature[0] !== undefined) {

            for (const f of selectedFeature) {
                const geoJson = new GeoJSON();
                feature = f.type ? geoJson.readFeature(f) : f;
                geom = feature.getGeometry();
                vectorSource.addFeature(feature);
            }

            if (selectedFeature.length === 1 && goToSelection) {
                map.getView().fit(geom, { padding: [20, 20, 100, 20], duration: 500, maxZoom: 19 });
                dispatch(setGoToSelection(false))
            }
            if (selectedFeature.length > 1 && goToSelection) {
                map?.getView().fit(tableExtent, { duration: 500, maxZoom: 19 });
                dispatch(setGoToSelection(false))
            }

        }
        else if (vertexFeature && vertexFeature.length > 0 && vertexFeature[0] !== undefined) {

            for (const f of vertexFeature) {
                const geoJson = new GeoJSON();
                feature = f.type ? geoJson.readFeature(f) : f;
                geom = feature.getGeometry();
                vectorSource.addFeature(feature);
            }

            if (vertexFeature.length === 1 && goToSelection) {
                map.getView().fit(geom, { padding: [20, 20, 100, 20], duration: 500, maxZoom: 19 });
                dispatch(setGoToSelection(false))
            }
            if (vertexFeature.length > 1 && goToSelection) {
                map?.getView().fit(tableExtent, { duration: 500, maxZoom: 19 });
                dispatch(setGoToSelection(false))
            }
        }

        return () => {
            map.removeLayer(vector)
            map.removeLayer(vectorx)


        }
    }, [selectedFeature, goToSelection, tableExtent, vertexFeature])

    return (
        <>
        </>
    )
}
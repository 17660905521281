import { createSlice } from '@reduxjs/toolkit';
import LayerGroup from 'ol/layer/Group';
import { Project } from '../../util/model/project';

interface LayerState {
    projects: Project[],
    layerGroups: LayerGroup[],
    selectedLayer: {},
    selectedLayerTable:{},
    styles: null,
    services: [],
    geoserverUrl: string,
    geoserverUrlTable: string,
    workspace: string,
    visibleDomains: any,
    isLayerVisible: boolean,
    externalLayerExtent: [],
    enabledGroupAlias: string,
    offlines: [],
    selectedKeysforPano: {},
    layerNodes:[]
}

const initialState: LayerState = {
    projects: [],
    layerGroups: [],
    selectedLayer: {},
    selectedLayerTable:{},
    styles: null,
    services: [],
    geoserverUrl: '',
    geoserverUrlTable:'',
    workspace: '',
    visibleDomains: [],
    isLayerVisible: false,
    externalLayerExtent: [],
    enabledGroupAlias: "",
    offlines: [],
    selectedKeysforPano: {},
    layerNodes:[]
}

export const layerSlice = createSlice({
    name: 'layer',
    initialState,
    reducers: {
        setProjects: (state: any, action) => {
            state.projects = action.payload;
        },
        setLayerGroups: (state: any, action) => {
            state.layerGroups = action.payload;
        },
        setSelectedLayer: (state: any, action) => {
            state.selectedLayer = action.payload;
        },
        setSelectedLayerTable: (state: any, action) => {
            state.selectedLayerTable = action.payload;
        },
        setSelectedLayerGeoserverUrlTable: (state: any, action) => {
            state.geoserverUrlTable = action.payload;
        },
        setSelectedLayerGeoserverUrl: (state: any, action) => {
            state.geoserverUrl = action.payload;
        },
        setWorkspace: (state: any, action) => {
            state.workspace = action.payload;
        },
        setStyles: (state: any, action) => {
            state.styles = action.payload;
        },
        setServices: (state: any, action) => {
            state.services = action.payload;
        },
        setVisibleDomains: (state: any, action) => {
            state.visibleDomains = action.payload;
        },
        setIsLayerVisible: (state: any, action) => {
            state.isLayerVisible = action.payload;
        },
        setExternalLayerExtent: (state: any, action) => {
            state.externalLayerExtent = action.payload;
        },
        setEnabledGroupAlias: (state: any, action) => {
            state.enabledGroupAlias = action.payload;
        },
        setOfflines: (state: any, action) => {
            state.offlines = action.payload;
        },
        setSelectedKeysforPano: (state: any, action) => {
            state.selectedKeysforPano = action.payload;
        },
        setLayerNodes: (state: any, action) => {
            state.layerNodes = action.payload;
        },
    }
})

export const { setProjects, setSelectedLayerTable, setSelectedLayerGeoserverUrlTable, setLayerGroups, setWorkspace, setSelectedLayerGeoserverUrl, setSelectedLayer, setStyles, setServices, setVisibleDomains, setIsLayerVisible, setExternalLayerExtent, setEnabledGroupAlias, setOfflines, setSelectedKeysforPano, setLayerNodes } = layerSlice.actions

export default layerSlice.reducer
import { useContext, useEffect, useState } from 'react'
import { MapContext } from '../../map-container/map/MapContext';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import { useDispatch } from 'react-redux';
import { DigiDrawLinestring } from './digi-draw/DigiDrawLinestring';
import { DigiDrawPolygon } from './digi-draw/DigiDrawPolygon';
import { useAppSelector } from '../../../../state/hooks';
import { DigiDrawPoint } from './digi-draw/DigiDrawPoint';
import { ButtonState } from '../../../shared/button-state/ButtonState';
import { setCancelDigiActivate, setEditedProperties } from '../../../../state/features/digitizationSlice';
import { setButtonState } from '../../../../state/features/buttonStateSlice';
import { DigiEdit } from './digi-operations/DigiEdit';
import { setSelectedFeature, setVertexFeature } from '../../../../state/features/selectedFeatureSlice';
import { SplitLine } from './digi-draw/SplitLine';
import { MoveFeature } from './digi-draw/MoveFeature';
import { TakeParallel } from './take-parallel/TakeParallel';
import { DrawHole } from './digi-draw/DrawHole';
import { DrawStateType } from '../../../../util/model/drawState';

/* 
 * Sayısallaştırmanın ilk başladığı component. Burada geom tiplerine göre diğer componentler çağırılıyor.
 *
*/
export const Digitization = () => {
    const map = useContext(MapContext);
    const dispatch = useDispatch();
    const [vector, setVector] = useState<VectorLayer<any>>(new VectorLayer())
    const [source, setSource] = useState<VectorSource<any>>(new VectorSource())
    const cancelDigiActivate = useAppSelector((state) => state.digitization.cancelDigiActivate)
    const selectedLayer: any = useAppSelector(state => state.layer.selectedLayer)
    const buttonState: any = useAppSelector(state => state.buttonState.buttonState)
    const panoramaType = useAppSelector(state => state.panorama.type);
    const selectedFeature: any = useAppSelector(state => state.selectedFeature.feature);

    useEffect(() => {
        const source = new VectorSource({ wrapX: false });
        const vector = new VectorLayer({
            source: source,
            zIndex: 1000,
            style: new Style({
                fill: new Fill({
                    color: 'A52125',
                }),
                stroke: new Stroke({
                    color: 'red',
                    width: 3,
                }),
            }),
        });

        map.addLayer(vector);
        setVector(vector)
        setSource(source)
        return () => {
            map.removeLayer(vector)
        }
    }, [])

    useEffect(() => {
        if (cancelDigiActivate) {
            const layer_:any = map.getLayers().getArray().find((el:any)=> el.getProperties().alias === 'draws')
            const draws:any = [...layer_.getSource().getFeatures().filter((el:any) => el.get('alias') !== 'draws')]
            // console.log("addFeatures",draws);
            if(draws.length){
                source.clear();
                source.addFeatures(draws);
            }else{
                source.clear();
            }
            map.updateSize();
            // if (vector.getSource().updateParams != undefined) {

            //     vector.getSource().updateParams({
            //         'time': Date.now()
            //     })
            // }
            dispatch(setCancelDigiActivate(false));
            dispatch(setButtonState(ButtonState.NONE))
            dispatch(setSelectedFeature([]));
            dispatch(setVertexFeature([]));
        }
    }, [cancelDigiActivate])

    useEffect(() => {
        /**
         * test
         */
      /*   const layer_:any = map.getLayers().getArray().find((el:any)=> el.getProperties().alias === 'draws')
        const draws:any = [...layer_.getSource().getFeatures().filter((el:any)=>el.get('alias') !== 'draws')]
        if(draws.length){
            source.clear();
            source.addFeatures(draws)
        }else{
            source.clear();
        } */
        
        // source.clear();
        dispatch(setEditedProperties(null))
    }, [buttonState])

    return (
        <>
            {selectedLayer && selectedLayer.geomtype === 'MultiPointZ' && buttonState === ButtonState.ADD && panoramaType === 'Floor' && <DigiDrawPoint source={source} />}
            {selectedLayer && selectedLayer.geomtype === 'MultiLineStringZ' && (buttonState === ButtonState.ADD) && panoramaType === 'Floor' && <DigiDrawLinestring source={source} />}
            {selectedLayer && selectedLayer.geomtype === 'MultiPolygonZ' && buttonState === ButtonState.ADD && panoramaType === 'Floor' && <DigiDrawPolygon source={source} />}
            {selectedFeature.length !== 0 && selectedLayer.geomtype === 'MultiLineStringZ' && buttonState === ButtonState.SPLIT && <SplitLine />}
            {selectedFeature.length !== 0 && buttonState === ButtonState.PARALLEL && <TakeParallel />}
            {selectedFeature.length !== 0 && buttonState === ButtonState.MOVE && <MoveFeature />}
            {selectedLayer && buttonState === ButtonState.EDIT && <DigiEdit />}
            {(selectedLayer.name && buttonState === ButtonState.HOLE && (selectedFeature.length === 1 && (selectedLayer.name === selectedFeature[0].id.split('.')[0]))) && <DrawHole source={source} />}
        </>
    )
};


import { MultiPolygon, MultiLineString } from 'ol/geom';
import WKT from 'ol/format/WKT';
import axios from 'axios';

  const getMiddlePointOfLine = (wkt: any): any => {
    const url = process.env.REACT_APP_BASE_URL + 'rest/v1/util/middlepoint'
    const body = {
      wkt: wkt
    }
    return new Promise((resolve, reject) => {

      axios.post(url, body).then((res: any) => {

        const obj = {
          middlePoint: res.data.middlePoint,
        }
        resolve(obj)
      },
        (error:any) => {
          reject(error)
        });
    })
  }
  const getPerimeterOfPolygon = (wkt:any): any =>  {
    const url = process.env.REACT_APP_BASE_URL + 'rest/v1/util/perimeter'
    const body = {
      wkt: wkt
    }
    return new Promise((resolve, reject) => {
        axios.post(url, body).then((res: any) => {
        const obj = {
          perimeter: res.data.perimeter,
        }
        // console.log("res :",res);
        
        resolve(obj)
      },
        (error:any) => {
          reject(error)
        });
    })
  }

const calcAreaOrLength = () : any => {
    let measurementResult;

    (window as any).panogl.getScalable()?.getMainSketchLayer().refresh();

    const getGeomList :any = (window as any).panogl.getScalable()?.getMainSketchLayer().getGeomListClone();

    
    const gdh :any = getGeomList[getGeomList.length - 1]


    // console.log("calcAreaOrLength :", gdh);
    
    if (gdh?.attributes.hasOwnProperty('_areaSize')) {
        measurementResult = parseFloat(gdh?.attributes._areaSize.split('m')[0])
    } else if (gdh?.attributes.hasOwnProperty('_length')) {
        measurementResult = parseFloat(gdh?.attributes._length.split('m')[0])
    }
    return measurementResult;
}

 const calculateMeasureResult : any= async (feature:any, measurementResult:any, from_?:any) => {
    
    if (!feature) { return }
    const label :any = {
      location: undefined,
      locationX: undefined,
      locationY: undefined,
      locationZ: undefined,
      length: undefined,
      perimeter: undefined,
      Area: undefined,
      start_Point: undefined,
      End_Point: undefined,
      Middle_Point: undefined,
      start_Point_X: undefined,
      End_Point_X: undefined,
      Middle_Point_X: undefined,
      start_Point_Y: undefined,
      End_Point_Y: undefined,
      Middle_Point_Y: undefined,
      start_Point_Z: undefined,
      End_Point_Z: undefined,
      Middle_Point_Z: undefined
    };
    let geom;
    const wkt = new WKT()
    
    let _getGeometry = feature.getGeometry()
    const geomClone = _getGeometry ? _getGeometry.clone() : feature.values_.geometry.clone();
    switch (geomClone.getType()) {
      case 'LineString':
        const pointsline = geomClone.transform('EPSG:3857', 'EPSG:4326').getCoordinates()
        // console.log("pointsline :",pointsline);

        if(pointsline[0].length < 3) {
            pointsline.forEach((points:any) => {
                points.push(-5000)
            });
        }
        
       
        geom = new MultiLineString([pointsline])
        const geomWktLine = wkt.writeGeometry(geom)

     

        // console.log("geom : ",geom);
        
        geom.transform('EPSG:4326', 'EPSG:3857')
        // console.log("pointsline[0][2]  : ",pointsline[0][2]);
        // console.log("pointsline[0][2]  : ",pointsline[0][1]);
        // console.log("pointsline[0][2]  : ",pointsline[0][0]);

        let start3 = pointsline[0][2] ? pointsline[0][2].toFixed(2) : '-5000';
        
        
        label.length = measurementResult
        label.start_Point  = pointsline[0][0].toFixed(6) + ',' + pointsline[0][1].toFixed(6) + ',' + start3;
        label.start_Point_X = parseFloat(label.start_Point.split(',')[0]);
        label.start_Point_Y = parseFloat(label.start_Point.split(',')[1]);
        label.start_Point_Z = parseFloat(label.start_Point.split(',')[2]);


        let end3 = pointsline[pointsline.length - 1][2] ? pointsline[pointsline.length - 1][2].toFixed(2): '-5000'

        label.End_Point = pointsline[pointsline.length - 1][0].toFixed(6) + ',' + 
          pointsline[pointsline.length - 1][1].toFixed(6) + ',' + end3
        label.End_Point_X = parseFloat(label.End_Point.split(',')[0]);
        label.End_Point_Y = parseFloat(label.End_Point.split(',')[1]);
        label.End_Point_Z = parseFloat(label.End_Point.split(',')[2]);
        const response: { middlePoint?: any } = await getMiddlePointOfLine(geomWktLine).catch((err:any) => {
          // console.log("_________err : ", err);
          //this.drawFail();
        })
        // console.log("response : ",response);
        
        if (response) {
          label.Middle_Point = response.middlePoint
          label.Middle_Point_X = parseFloat(label.Middle_Point.split(',')[0]);
          label.Middle_Point_Y = parseFloat(label.Middle_Point.split(',')[1]);
          label.Middle_Point_Z = parseFloat(label.Middle_Point.split(',')[2]);
        }
        break;
        case 'MultiLineString':
         const condition_ = from_ === "Map"

        const multipointsline = geomClone.transform('EPSG:3857', 'EPSG:4326').getCoordinates()

        if(multipointsline[0][0].length < 3) {
            multipointsline[0].forEach((points:any) => {
                points.push(-5000)
            });
        }
       
        geom = new MultiLineString([multipointsline[0]])
        const multigeomWktLine = wkt.writeGeometry(geom)

        geom.transform('EPSG:4326', 'EPSG:3857')

        let multistart3 = multipointsline[0][0][2] ? multipointsline[0][0][2].toFixed(2) : '-5000';
        
        label.length = measurementResult
        label.start_Point  = multipointsline[0][0][0].toFixed(6) + ',' + multipointsline[0][0][1].toFixed(6) + ',' + multistart3;
        label.start_Point_X = parseFloat(label.start_Point.split(',')[0]);
        label.start_Point_Y = parseFloat(label.start_Point.split(',')[1]);
        label.start_Point_Z = parseFloat(label.start_Point.split(',')[2]);

        let multiend3 = multipointsline[0][multipointsline[0].length - 1][2] ? multipointsline[0][multipointsline[0].length - 1][2].toFixed(2): '-5000'

        label.End_Point = multipointsline[0][multipointsline[0].length - 1][0].toFixed(6) + ',' + 
          multipointsline[0][multipointsline[0].length - 1][1].toFixed(6) + ',' + multiend3
        label.End_Point_X = parseFloat(label.End_Point.split(',')[0]);
        label.End_Point_Y = parseFloat(label.End_Point.split(',')[1]);
        label.End_Point_Z = parseFloat(label.End_Point.split(',')[2]);
        const multiresponse: { middlePoint?: any } = await getMiddlePointOfLine(multigeomWktLine).catch((err:any) => {
          // console.log("_________err : ", err);
          //this.drawFail();
        })
        if (multiresponse) {
          label.Middle_Point = multiresponse.middlePoint
          label.Middle_Point_X = parseFloat(label.Middle_Point.split(',')[0]);
          label.Middle_Point_Y = parseFloat(label.Middle_Point.split(',')[1]);
          label.Middle_Point_Z = parseFloat(label.Middle_Point.split(',')[2]);
        }
        break;
        case 'Polygon':
        const points_ = geomClone.transform('EPSG:3857', 'EPSG:4326').getCoordinates()
        let pts= points_[0]
        if(points_[0][0].length < 3) {
            points_[0].forEach((points:any) => {
                points.push(-5000)
                
            });
        }
        if (Array.isArray(points_[0][0])) {
          geom = new MultiPolygon([[points_[0]]])
        } else {
          geom = new MultiPolygon([[points_]])
        }
        
        const geomWkt = wkt.writeGeometry(geom)
        geom.transform('EPSG:4326', 'EPSG:3857')
        const res: { perimeter?: any } = await getPerimeterOfPolygon(geomWkt).catch((err:any) => {
       //   this.drawFail();
        })
        
        if (res) {
          label.perimeter = (res.perimeter).toFixed(2)
          label.Area = measurementResult
        }
        break;

        case 'MultiPolygon':

        const points_Multi = geomClone.transform('EPSG:3857', 'EPSG:4326').getCoordinates()[0][0]
        // console.log("points Poly_ :",points_Multi);

        let ptsMulti= points_Multi[0]
        if(points_Multi[0][0].length < 3) {
            points_Multi[0].forEach((points:any) => {
                points.push(-5000)
                
            });
        }
        if (Array.isArray(points_Multi[0][0])) {
          geom = new MultiPolygon([[points_Multi[0]]])
        } else {
          geom = new MultiPolygon([[points_Multi]])
        }
        
        const geomWktMulti = wkt.writeGeometry(geom)
        geom.transform('EPSG:4326', 'EPSG:3857')
        const resMulti: { perimeter?: any } = await getPerimeterOfPolygon(geomWktMulti).catch((err:any) => {
       //   this.drawFail();
        })
        
        if (resMulti) {
          label.perimeter = (resMulti.perimeter).toFixed(2)
          label.Area = measurementResult
        }
        break;

        
      case 'Point':
       case 'MultiPoint':
        const condition = from_ === "Map"
        // console.log("feature:",feature);
        
       const geomCloned = /*feature.values_.location_z ? feature.values_.geometry.clone() : */_getGeometry ? _getGeometry.clone() : feature.values_.geometry.clone();
        
        const points = geomCloned.transform('EPSG:3857', 'EPSG:4326').getCoordinates().flat();
         if(points.length<3){
             points.push(-5000)
         }
        label.location = points[0].toFixed(6) + ',' + points[1].toFixed(6) + ','
         + ( condition ? feature.values_.location?.split(',')[2] : points[2].toFixed(2));
        label.locationX = parseFloat(label.location.split(',')[0]);
        label.locationY = parseFloat(label.location.split(',')[1]);
        label.locationZ = parseFloat(label.location.split(',')[2]);
        break;
      default:
    }
    return new Promise(resolve => {
      resolve(label)
    });
  }


const calculateResult = async (feature:any, from_?:any) => {
    const measurementResult : any = calcAreaOrLength(/*feature*/)
    
    const result:  {
        Area?: any,
        perimeter?: any,
        length?: any,
        start_Point?: any,
        End_Point?: any,
        Middle_Point?: any,
        location?: any,
        start_Point_X?: any,
        End_Point_X?: any,
        Middle_Point_X?: any,
        locationX?: any,
        start_Point_Y?: any,
        End_Point_Y?: any,
        Middle_Point_Y?: any,
        locationY?: any,
        start_Point_Z?: any,
        End_Point_Z?: any,
        Middle_Point_Z?: any,
        locationZ?: any
      } = await calculateMeasureResult(feature, measurementResult, from_);
   // createEmptyForm(measurementResult);
  //  console.log("result :",result);
   return result;
}
 
export default calculateResult;
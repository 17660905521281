import { createSlice } from '@reduxjs/toolkit'

interface PrintState {
    showPrint: boolean
    printCompleted:boolean,
    printPreviewMode:boolean
}

const initialState: PrintState = {
    showPrint: false,
    printCompleted:false,
    printPreviewMode: false
}

export const printSlice = createSlice({
    name: 'print',
    initialState,
    reducers: {
        setShowPrint: (state: any, action) => {
            state.showPrint = action.payload;
        },
        setPrintCompleted: (state: any, action) => {
            state.printCompleted = action.payload;
        },
        setPrintPreviewMode: (state: any, action) => {
            state.printPreviewMode = action.payload;
        },
    }
})

export const { setShowPrint, setPrintCompleted, setPrintPreviewMode } = printSlice.actions;

export default printSlice.reducer;
import './Import.scss';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  setImportCompleted,
  setImportedEpsg,
  setImportedFile,
  setImportLayerGroup,
  setImportName,
  setShowImport,
} from '../../state/features/importSlice';
import { setButtonState, setImportButton } from '../../state/features/buttonStateSlice';
import { ButtonState } from '../shared/button-state/ButtonState';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';

export const Import = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { epsgList } = require('../shared/epsg-list/epsgList.json')

  const [files, setFiles] = useState<Array<File | any>>([]);
  const [error, setError] = useState<number>(0);
  const [selectedEpsg, setSelectedEpsg] = useState<string>('');
  const buttonState = useAppSelector(state => state.buttonState.buttonState)
  const importLayerGroup: any = useAppSelector(
    (state) => state.import.importLayerGroup
  );
  
  const chooseOptions = {
    icon: '',
    label: t(`CONTEXT MENU.Choose File`),
    className: 'custom-choose-btn p-button-outlined'
  };
  const uploadOptions = {
    label: 'Uplaod',
    icon: 'pi pi-upload',
    className: 'p-button-success',
  };
  const cancelOptions = {
    label: 'Cancel',
    icon: 'pi pi-times',
    className: 'p-button-danger',
  };

  const handleSelectFile = async (e: any) => {
    const files2 = [...e.files]
    setFiles(files2)
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    dispatch(setImportCompleted(false));
    
    const name =
      files.length === 3
          ? files.find((el: any) => el.name.includes('.shp')).name
          : files[0].name;
    const offlineLayerGroup = {
      children: [
          { 
          key: importLayerGroup !== "" ? `Çevirimdışı - ${importLayerGroup.children[0].id + 1}` : "Çevirimdışı - 1",//files[0].name
          label: name, //files[0].name
          data: files[0],
          id: importLayerGroup !== "" ? importLayerGroup.children[0].id + 1 : 1,
          }
      ]
    }

    dispatch(setImportName(/*`Çevirimdışı - 1`*/name));
    dispatch(setImportLayerGroup(offlineLayerGroup));
    dispatch(setImportedEpsg(`EPSG:${selectedEpsg}`));  //`EPSG:4326`
    dispatch(setImportedFile(files));
    dispatch(setButtonState(ButtonState.NONE))
   
  }

  return (
    <div className='Import'>
      <Dialog
        className='import-dialog'
        header={t(`BUTTON.Import`)}
        visible={buttonState === ButtonState.IMPORT}
        onHide={() => {
          dispatch(setShowImport(false))
          dispatch(setImportButton(false))
          dispatch(setButtonState(ButtonState.NONE))
        }}
      >
        <form onSubmit={handleSubmit} className='import-form'>

          <FileUpload
            className='import-file-upload'
            multiple
            onSelect={handleSelectFile}
            accept='.json,.geojson,.kml,.gml,.shp,.dbf,.shx'
            chooseOptions={chooseOptions}
            uploadOptions={uploadOptions}
            cancelOptions={cancelOptions}
            onRemove={(e) => {
              setError(0);
              setFiles([]);
            }}
            // maxFileSize={1000000}
            emptyTemplate={
              <p className='m-0'>Geojson, Kml, ShapeFile, Gml</p>
            }
          />
          <div className="input-group">
            {/* <label htmlFor="data-type">{t('BUTTON.Epsg Code')}</label> */}
            <Dropdown
              id="epsg"
              filter
              filterBy="value"
              className="import-epsg-dropdown"
              value={selectedEpsg}
              options={epsgList}
              panelClassName='panel-dropdown'
              placeholder={t('BUTTON.Select Epsg Code')}
              optionLabel="value"
              onChange={(e) => setSelectedEpsg(e.value)}
            />
          </div>
          <div className='submit-button'>
            <Button
              label={t('BUTTON.Save')}
              type='submit'
            />
          </div>
        </form>

      </Dialog>
    </div>
  );
}

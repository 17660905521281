import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env

const getToken = () => {
    return localStorage.getItem('token')
}

async function getPermisionOfUserOnLayer(layerId: any) {
    return await axios.get(`${REACT_APP_BASE_URL}rest/v1/role/permission/layer/${layerId}?token=${getToken()}`)
}

export const Permission = {
    getPermisionOfUserOnLayer
}
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { useContext, useEffect, useState } from 'react'
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import { MapContext } from '../../../map-container/map/MapContext';
import { Mode } from '../TransactWfs';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../state/hooks';
import { setSelectedFeature } from '../../../../../state/features/selectedFeatureSlice';
import { setCancelDigiActivate, setConfirmPopupVisibility } from '../../../../../state/features/digitizationSlice';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import { setButtonState } from '../../../../../state/features/buttonStateSlice';
import ConfirmPopup from '../confirm-popup/ConfirmPopup';
import { useTranslation } from 'react-i18next';

/* 
 * Bu componentte seçilen feature önce haritada gösteriliyor, sonra geometrisi ve property leri değiştirilebiliyor.
 *
*/
export const DigiEdit = () => {
  const map = useContext(MapContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [source, setSource] = useState<VectorSource<any>>(new VectorSource())
  const [vectorLayer, setVectorLayer] = useState<VectorLayer<any>>(new VectorLayer())
  const [olFeatureForEdit, setolFeatureForEdit] = useState<any>(new Feature())
  const selectedFeature: any = useAppSelector(state => state.selectedFeature.feature);
  const cancelDigiActivate = useAppSelector((state) => state.digitization.cancelDigiActivate)
  const confirmPopupVisibility: any = useAppSelector(state => state.digitization.confirmPopupVisibility)
  const buttonState: any = useAppSelector(state => state.buttonState.buttonState)
  const selectedLayer: any = useAppSelector(state => state.layer.selectedLayer)

  useEffect(() => {
    map.updateSize();
  }, [])

  const style = new Style({
    fill: new Fill({
      color: 'rgb(56, 238, 245, 0.44)',
    }),
    stroke: new Stroke({
      color: '#38EEF5',
      width: 2,
    }),
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({
        color: '#38EEF5',
      }),
    }),
  })


  useEffect(() => {
    if (selectedFeature[0]) {
      const vectorSource = new VectorSource();

      const vector = new VectorLayer({
        source: vectorSource,
        zIndex: 10000000000000000,
        style: style
      });
      map.addLayer(vector);
      setVectorLayer(vector)

      const geoJson = new GeoJSON()
      for (const f of selectedFeature) {
        const feature: any = geoJson.readFeature(f)

        if (feature) {
          vectorSource.addFeature(feature);
          setSource(vectorSource)
          if (feature && feature.getGeometry()) {
            setolFeatureForEdit(feature);
          }
        }
      }
      return () => {
        map.removeLayer(vector);
        source.clear();
      }
    }
  }, [selectedFeature])

  useEffect(() => {
    if (olFeatureForEdit) {
      dispatch(setConfirmPopupVisibility(true))
    }
  }, [olFeatureForEdit])

  useEffect(() => {
    if (cancelDigiActivate) {
      dispatch(setSelectedFeature([]))
      dispatch(setCancelDigiActivate(false));
      dispatch(setButtonState(ButtonState.NONE))
    }
  }, [cancelDigiActivate])

  return (
    <>
      {confirmPopupVisibility && selectedFeature[0] && (selectedLayer.name && selectedFeature.length >= 1 && selectedLayer.name === selectedFeature[0].id.split('.')[0]) && (buttonState === ButtonState.VERTEX || buttonState === ButtonState.EDIT) && <ConfirmPopup mode={Mode.UPDATE} title={t('TEXT.Edit Attribute')} from='Map' content={Mode.UPDATE} />}
    </>
  )
}

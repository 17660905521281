import { useContext, useEffect, useState } from 'react'
import { MapContext } from '../MapContext'
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import { useAppSelector } from '../../../../../state/hooks';
import { DrawStateType } from '../../../../../util/model/drawState';
import GeoJSON from 'ol/format/GeoJSON';
import CircleStyle from 'ol/style/Circle';
import Text from 'ol/style/Text';
import { Snap } from 'ol/interaction';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';

export const Cursor = () => {

  const map = useContext(MapContext);
/*   const drawState = useAppSelector(state => state.drawState.value);
  const panoramaCoordinates = useAppSelector((state) => state.coordinates.panoCoordinates);
  const panoramaTransactStatus = useAppSelector(state => state.digitization.panoramaTransactStatus)

  const [vector, setVector] = useState<VectorLayer<any>>(new VectorLayer())
  const [source, setSource] = useState<VectorSource<any>>(new VectorSource())
  const buttonState: any = useAppSelector(state => state.buttonState.buttonState) */
  
  const [cursorLayer, setCursorLayer] = useState<VectorLayer<any>>(new VectorLayer())


  const cursorCoordinate: any = useAppSelector(state => state.panorama.cursorCoordinate)

  const [cursorFeature, setCursorFeature] = useState<any>(new Feature({
    geometry: new Point(cursorCoordinate)
  }))


  useEffect(() => {
    if (cursorFeature) {
        cursorFeature.getGeometry().setCoordinates(cursorCoordinate);
      } else {
        setCursorFeature(new Feature({
          geometry: new Point(cursorCoordinate)
        }));
      }
  }, [cursorCoordinate]);

  useEffect(() => {
    const cursorStyle = new Style({
        image: new Icon({
            src: '/img/crosshair.png',
            scale: 1,
            color: 'rgba(255, 0, 0, .5)',
            crossOrigin: 'anonymous',
        })
    });

    const cursorSource = new VectorSource({
        features: [cursorFeature]
    });
    const _layer = new VectorLayer({
        source: cursorSource,
        style: cursorStyle,
        properties:{
            alias: 'cursorSourcedraws'
        }
    })
    setCursorLayer(_layer);
    map.addLayer(_layer)
    return () => {
      map.removeLayer(_layer)
    }
  }, [])

  return (
    <>
    </>
  )
}

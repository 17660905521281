import './Intro.scss';
import 'intro.js/introjs.css';
import { Steps } from 'intro.js-react';
import 'intro.js/themes/introjs-modern.css';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  setButtonState,
  setSidebarButton,
  setTableButton,
} from '../../state/features/buttonStateSlice';
import { ButtonState } from '../shared/button-state/ButtonState';
import { useState } from 'react';

export const Intro = () => {
  const dispatch = useAppDispatch();
  const LoginImg = process.env.PUBLIC_URL + '/img/intro-images/logintry.png';
  const ExpandLayersImg =
    process.env.PUBLIC_URL + '/img/intro-images/exp-layers.png';
  const HideLayersImg =
    process.env.PUBLIC_URL + '/img/intro-images/hiding-layers.png';
  const VisibltyImg = process.env.PUBLIC_URL + '/img/intro-images/visiblty.png';
  const SelectImg = process.env.PUBLIC_URL + '/img/intro-images/selecting.png';
  const RightClckImg =
    process.env.PUBLIC_URL + '/img/intro-images/right-click-layers.png';
  const OpnClsTableImg =
    process.env.PUBLIC_URL + '/img/intro-images/open-close-table.png';
  const ExprtLyrImg =
    process.env.PUBLIC_URL + '/img/intro-images/export-layer.png';
  const ExprtPopUpImg =
    process.env.PUBLIC_URL + '/img/intro-images/export-popup.png';
  const PanoramaImg = process.env.PUBLIC_URL + '/img/intro-images/panorama.png';
  const PanrmPointCld =
    process.env.PUBLIC_URL + '/img/intro-images/panorama-point-cloud.png';
  const EngFlrToggle =
    process.env.PUBLIC_URL + '/img/intro-images/eng-floor-toggle.png';
  const EngPointToggle =
    process.env.PUBLIC_URL + '/img/intro-images/eng-point-toggle.png';
  const EngMultiToggle =
    process.env.PUBLIC_URL + '/img/intro-images/eng-multi-toggle.png';
  const FloorMtImg =
    process.env.PUBLIC_URL + '/img/intro-images/floor-method.png';
  const PntCldImg =
    process.env.PUBLIC_URL + '/img/intro-images/point-cloud.png';
  const MultiPano =
    process.env.PUBLIC_URL + '/img/intro-images/multi-screenpano.png';
  const DigiPntFloor =
    process.env.PUBLIC_URL + '/img/intro-images/digi-pointlyr-floor.png';
  const DigiPntCld =
    process.env.PUBLIC_URL + '/img/intro-images/digi-pointlyr-pointcloud.png';
  const DigiPntMulti =
    process.env.PUBLIC_URL + '/img/intro-images/digi-pointlyr-multi.png';
  const EditImg = process.env.PUBLIC_URL + '/img/intro-images/edit-btn.png';
  const VertexImg = process.env.PUBLIC_URL + '/img/intro-images/vertex-edt.png';
  const MoveGeoImg = process.env.PUBLIC_URL + '/img/intro-images/move-geo.png';
  const DeleteData =
    process.env.PUBLIC_URL + '/img/intro-images/delete-data.png';
  const SpltLine = process.env.PUBLIC_URL + '/img/intro-images/splt-line.png';
  const Parallel = process.env.PUBLIC_URL + '/img/intro-images/parallel.png';
  const PerfPoly = process.env.PUBLIC_URL + '/img/intro-images/perf-polyg.png';
  const InfoTool = process.env.PUBLIC_URL + '/img/intro-images/info-tool.png';
  const SlctTool = process.env.PUBLIC_URL + '/img/intro-images/slct-tool.png';
  const AttrTool = process.env.PUBLIC_URL + '/img/intro-images/attr-tool.png';
  const QckSrch = process.env.PUBLIC_URL + '/img/intro-images/qck-srch.png';
  const PointCoor = process.env.PUBLIC_URL + '/img/intro-images/point-coor.png';
  const LgntMsrmt = process.env.PUBLIC_URL + '/img/intro-images/lgnt-msrmt.png';
  const AreaMsrmt =
    process.env.PUBLIC_URL + '/img/intro-images/area-msrmnt.png';
  const PerpLenght =
    process.env.PUBLIC_URL + '/img/intro-images/perp-length.png';
  const HghtMsr = process.env.PUBLIC_URL + '/img/intro-images/hght-msr.png';
  const HghtMsr2 =
    process.env.PUBLIC_URL + '/img/intro-images/hgh-msr-wth-2.png';
  const GridM = process.env.PUBLIC_URL + '/img/intro-images/grid-m.png';
  const ImportLyr = process.env.PUBLIC_URL + '/img/intro-images/import-lyr.png';
  const ExportLyr = process.env.PUBLIC_URL + '/img/intro-images/export-lyr.png';
  const Print = process.env.PUBLIC_URL + '/img/intro-images/print.png';
  const EditBtnImg =
    process.env.PUBLIC_URL + '/img/intro-images/edit-btn-alone.png';
  const EditVertexBtn =
    process.env.PUBLIC_URL + '/img/intro-images/edit-vertex-btn.png';
  const DltBtn = process.env.PUBLIC_URL + '/img/intro-images/delete-btn.png';
  const SpltLineBtn =
    process.env.PUBLIC_URL + '/img/intro-images/split-line-btn.png';
  const MoveFeatBtn =
    process.env.PUBLIC_URL + '/img/intro-images/move-feature-btn.png';
  const PrlFeatBtn =
    process.env.PUBLIC_URL + '/img/intro-images/parallel-feature-btn.png';
  const PerfPolyBtn =
    process.env.PUBLIC_URL + '/img/intro-images/perf-poly-btn.png';
  const PointBtn = process.env.PUBLIC_URL + '/img/intro-images/point-btn.png';
  const LineBtn = process.env.PUBLIC_URL + '/img/intro-images/line-btn.png';
  const PolyBtn = process.env.PUBLIC_URL + '/img/intro-images/poly-btn.png';
  const HeightMsrBtn =
    process.env.PUBLIC_URL + '/img/intro-images/height-msr-btn.png';
  const PerpLenghtBtn =
    process.env.PUBLIC_URL + '/img/intro-images/perp-length-btn.png';
  const GridMsrBtn =
    process.env.PUBLIC_URL + '/img/intro-images/grid-msr-btn.png';
  const ClearBtn = process.env.PUBLIC_URL + '/img/intro-images/clear-btn.png';
  const ImportImg =
    process.env.PUBLIC_URL + '/img/intro-images/import-opened.png';

  const buttonState = useAppSelector((state) => state.buttonState.buttonState);
  //const language = useAppSelector((state) => state.language.value); //Türkçesi geldiğinde lazım olur
  const [enabled, setEnabled] = useState(true);
  const [initialStep, setInitialStep] = useState(0);

  const steps = [
    {
      element: '.introjs-relativePosition',
      intro: `<img src='${LoginImg}' alt='Login Screen Image'/>The application is opened by entering http://atlant.ankageo.com/gis in the internet browser. When the application is opened, login is made with username and password information.`,
      position: 'top-middle-aligned',
      tooltipClass: 'TooltipCustomClass',
      title: 'LOGIN',
      showStepNumbers: true,
    },
    {
      element: '.logout-button',
      intro:
        'Log out of the application is made by clicking on the "Log Out" button located in the top bar of the application.',
      position: 'left',
      tooltipClass: 'TooltipCustomClassToolbar',
      title: 'LOGOUT',
    },
    {
      element: '#layers-svg',
      intro:
        'The layers panel opens and closes by clicking the layers symbol located to the left of the top bar of the application.',
      position: 'right',
      title: 'Open and Close Layers Panel',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.Sidebar.non-printable',
      intro: `To view the layers within the layer groups in the layer panel, expand the layer group by clicking the  >  symbol to the left of the group name. Thus, the layers in the layer group are listed.<img src='${ExpandLayersImg}' alt='Expanding Layers Image'/>`,
      position: 'right',
      title: 'Expanding and Hiding Layer Groups',
      tooltipClass: 'TooltipCustomClassExp',
    },
    {
      element: '.Sidebar.non-printable',
      intro: `To hide the layers within the layer groups in the layer panel, click on the downward arrow symbol next to the group name to collapse it. Thus, the layers in the layer group are hidden.<img src='${HideLayersImg}' alt='Hide Layers Image'/>`,
      position: 'right',
      title: 'Expanding and Hiding Layer Groups',
      tooltipClass: 'TooltipCustomClassExp',
    },
    {
      element: '.Sidebar.non-printable',
      intro: `The visibility of the layers is adjusted from the square boxes next to the names of the layers. The layer becomes visible when the boxes are turned blue by clicking and a ✓ sign is formed. When you click again and the box becomes colorless and the ✓ sign is removed, the layer will no longer appear on the screen. <img src='${VisibltyImg}' alt='Visibility of Layers Image'/>`,
      position: 'right',
      title: 'Visibility of Layers',
      tooltipClass: 'TooltipCustomClassExp',
    },
    {
      element: '.Sidebar.non-printable',
      intro: `Layer selection is made by clicking on whichever layer listed to be processed. By selecting the layer, editing tools in the top bar above become active for the selected layer.<img src='${SelectImg}' alt='Selecting Layer Image'/>`,
      position: 'right',
      title: 'Selecting Layer',
      tooltipClass: 'TooltipCustomClassExp',
    },
    {
      element: '.Sidebar.non-printable',
      intro: `When right-clicking on the selected layer in the Layers panel, the "Go to the Layer", "Open/Close Table" and "Export" options open. The “Go to the Layer” option moves the map to the area where the data in the layer is located and makes the data visible.<img src='${RightClckImg}' alt='Layer Right-Click Menu Image'/>`,
      position: 'right',
      title: 'Layers Right-Click Menu ',
      tooltipClass: 'TooltipCustomClassExp',
    },
    {
      element: '.Sidebar.non-printable',
      intro: `When the selected layer in the Layers panel is clicked with the right mouse button and "Open/Close Table" is selected from the window that opens, the table containing the attribute information of the relevant layer is opened and closed.<img src='${OpnClsTableImg}' alt='Open/Close Table Image'/>`,
      position: 'right',
      title: 'Open/Close Table',
      tooltipClass: 'TooltipCustomClassExp',
    },
    {
      element: '.Sidebar.non-printable',
      intro: `When the selected layer in the Layers panel is clicked with the right mouse button, the relevant layer is exported with the "Export" option in the window that opens.<img src='${ExprtLyrImg}' alt='Export Layer Image'/>`,
      position: 'right',
      title: 'Export Layer',
      tooltipClass: 'TooltipCustomClassExp',
    },

    {
      element: '.image-box',
      intro:
        'Basemaps can be changed from the bottom-left part of the map screen. The application includes Carto Black, Carto Light, Google Satellite, Google Imagery, Open Street Map and Topographic basemaps.',
      position: 'right',
      title: 'Basemaps',
      tooltipClass: 'TooltipCustomClassExp',
    },
    {
      element: '.pano ',
      intro:
        'When clicking on the desired point on the Map Screen, a panoramic view of that point opens on the panorama screen. The image is rotated in the desired direction with the help of the left mouse button.',
      position: 'left',
      title: 'Panorama Screen',
      tooltipClass: 'TooltipCustomClassExp',
    },
    {
      element: '.pano-button-group',
      intro: `<img src='${PanoramaImg}' alt='Panorama Image'/><b>Direction Arrows:</b> Move back and forward with the arrows on the screen. The up-down arrows on the keyboard move one image at a time, and the right-left arrows move back and forward five images at a time.
      <br/><b>Layer Visibility Distance Adjustment:</b> Layer visibility distance is adjusted from the adjustment bars on the Panorama screen.
      <br/><b>Brightness Adjustment:</b> The brightness of the image is adjusted from the adjustment bars on the panorama screen.`,
      position: 'left',
      title: 'Panorama Screen',
      tooltipClass: 'TooltipCustomClassPano',
    },
    {
      element: '.pano-button-group',
      intro: `<img src='${PanrmPointCld}' alt='Panorama Point Cloud Image'/><b>Opening Point Cloud:</b> Allows to show the point cloud of the places visible in the panorama, and the point cloud can be shown in the side tab.`,
      position: 'left',
      title: 'Panorama Screen',
      tooltipClass: 'TooltipCustomClassPano',
    },
    {
      element: '.pano-type-dropdown',
      intro: `<strong>Digitization and Measurement Methods:</strong><br/> Measurement and digitization can be done with 3 different methods. Among the methods to be used: Point Cloud, Multi-Screen and Ground are selected on the top toolbar of the application.<span><img src='${EngFlrToggle}' alt='English Floor Toggle'/><br/></span><br/><b>Floor Method</b><br/>The Floor is selected among the digitization methods in the top toolbar of the application. This method is preferred when digitizing or measuring near points on the ground.<img src='${FloorMtImg}' alt='Floor Method Image'/>`,
      position: 'right',
      title: 'Top Toolbar',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.pano-type-dropdown',
      intro: `<span><img src='${EngPointToggle}' alt='English Point Toggle'/><br/></span><strong>Point Cloud Method:</strong><br/> Point Cloud is selected among the measurement and digitization methods in the top toolbar of the application. While measuring or digitizing with the help of coordinated point clouds, point cloud visibility can be turned on or off, if desired, from the option on the panorama.<img src='${PntCldImg}' alt='Point Cloud Image'/>`,
      position: 'right',
      title: 'Top Toolbar',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.pano-type-dropdown',
      intro: `<span><img src='${EngMultiToggle}' alt='English Multi Toggle'/><br/></span><strong>Multi Display Method:</strong><br/> Multi-Display method is selected among the digitization methods in the top toolbar of the application.Measurement or digitization is done on 2 or 3 screens. Digitization is done on multiple screens by marking the same point on the screens for the point to be digitized.<img src='${MultiPano}' alt='Multi Panorama Image'/>`,
      position: 'right',
      title: 'Top Toolbar',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.digi-button-box',
      intro: `<span><img src='${DigiPntFloor}' alt='Digitizing Layer with Floor Image'/></span><br/><strong>Digitizing Layer with Floor Method:</strong><br/>In order to digitize using the floor method, firstly, the layer to be digitized must be selected from the layers panel. Select “Floor” as the digitization method from the left part of the top toolbar and select the “Add” tool next to the method tool. The point to be digitized is clicked on the panorama and a pop-up opens on the screen to enter the attribute information.`,
      position: 'bottom',
      title: 'Digitization',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.digi-button-box',
      intro: `<span><img src='${DigiPntCld}' alt='Digitizing Layer with Point Cloud Method'/></span><br/><strong>Digitizing Layer with Point Cloud Method:</strong><br/>In order to digitize using the Point Cloud method, firstly, the layer to be digitized must be selected from the layers panel. Select “Point Cloud” as the digitization method from the left part of the top toolbar and select the “Add” tool next to the method tool. If you want to display the point clouds in the region to be digitized, the "Point Cloud" option on the right side of the panorama screen is selected. The point to be digitized is clicked on the panorama and a pop-up opens on the screen to enter the attribute information.`,
      position: 'bottom',
      title: 'Digitization',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.digi-button-box',
      intro: `<span><img src='${DigiPntMulti}' alt='Digitizing Layer with Multi Display Method'/></span><br/><strong>Digitizing Layer with Multi Display Method:</strong><br/>In order to digitize using the Multi display method, firstly, the layer to be digitized must be selected from the layers panel. Select “Multi Display” as the digitization method from the left part of the top toolbar and the panorama screen is divided into 3 windows. Select the “Add” tool on the top toolbar. Then, the same points are digitized in the 3 windows. The point you want to digitize on the panorama is clicked with the left mouse button on the first screen. After clicking the same point on the second  and third screens, the process is completed with the right mouse button. After entering the required attribute information, digitization is completed by clicking “Save”. `,
      position: 'bottom',
      title: 'Digitization',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.digi-button-box',
      intro: `<span><img src='${EditImg}' alt='Editing Attributes Image'/></span><span><img src='${EditBtnImg}' alt='Edit Button Image'/></span><strong>Editing Attributes</strong><br/>By clicking on a data using the "Edit" tool on the left side of the top toolbar, the attribute informations of the data can be changed and saved. `,
      position: 'bottom',
      title: 'Digitization',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.digi-button-box',
      intro: `<span><img src='${VertexImg}' alt='Vertex Editing Image'/></span><span><img src='${EditVertexBtn}' alt='Edit Vertex Button Image'/></span><strong>Vertex Editing</strong><br/>With the Edit Vertex tool, the third tool on the left side of the top toolbar, the corner vertexes of the data in the line or area layers can be changed and saved by right-clicking.`,
      position: 'bottom',
      title: 'Digitization',
      tooltipClass: 'TooltipCustomClassToolbar',
    },

    {
      element: '.digi-button-box',
      intro: `<span><img src='${DeleteData}' alt='Deleting Data Image'/></span><span><img src='${DltBtn}' alt='Delete Button Image'/></span><strong>Deleting Data</strong><br/>When clicking on the data in the layer intended to delete, the data is deleted after confirmation for deletion.`,
      position: 'bottom',
      title: 'Digitization',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.digi-button-box',
      intro: `<span><img src='${SpltLine}' alt='Split Line Image'/></span><span><img src='${SpltLineBtn}' alt='Split Line Button Image'/></span><strong>Split Line</strong><br/>With the "Line Split" tool, two different lines can be created by dividing the data in the line layers at any desired location. With the "Line Split" tool, any line data is cut by drawing a line from the desired part of the data and the process is confirmed to be saved by right-clicking.`,
      position: 'bottom',
      title: 'Digitization',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.digi-button-box',
      intro: `<span><img src='${MoveGeoImg}' alt='Move Geometry Image'/></span><span><img src='${MoveFeatBtn}' alt='Move Feature Button Image'/></span><strong>Move Geometry</strong><br/>The position of a data geometry can be changed with the Feature Move tool located on the left side of the top toolbar. In order to edit the position of an object on the map, the position of the data can be changed by clicking on the  "Move" tool.`,
      position: 'bottom',
      title: 'Digitization',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.digi-button-box',
      intro: `<span><img src='${Parallel}' alt='Parallel Feature'/></span><span><img src='${PrlFeatBtn}' alt='Parallel Feature Button Image'/></span><strong>Parallel Feature</strong><br/>"Parallel Feature" tool allows the copy of a line to be translated on different axes and provides the create parallel of the line. In order to use the "Parallel Feature" tool, the line that you want to take parallel must first be selected with the Selection tool.`,
      position: 'bottom',
      title: 'Digitization',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.digi-button-box',
      intro: `<span><img src='${PerfPoly}' alt='Perforated Polygon'/></span><span><img src='${PerfPolyBtn}' alt='Perforated Polygon Button Image'/></span><strong>Perforated Polygon</strong><br/>The Perforated Polygon tool allows to create a space by removing parts from a polygon data. To obtain a polygon with rings, first select the layer to process and then select a polygon object. After selecting the polygon, the “Perforated Polygon” tool is selected and the part intended to remove from the selected polygon is drawn on the map and saved by right-clicking.`,
      position: 'bottom',
      title: 'Digitization',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '#info-svg',
      intro: `<span><img src='${InfoTool}' alt='Information Tool'/></span><strong>Information Tool</strong><br/>When clicking on the objects on the map with the information tool, an information card with information about that object opens. After selecting the Information tool, click on the data.`,
      position: 'right',
      title: 'Digitization',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '#selection-svg',
      intro: `<span><img src='${SlctTool}' alt='Selection Tool'/></span><strong>Selection Tool</strong><br/>An object is selected on the map with the selection tool. If there are close data in the selected area, a data list appears where the desired data will be selected. The selection is performed by selecting data in the data list.`,
      position: 'right',
      title: 'Digitization',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '#table-svg',
      intro: `<span><img src='${AttrTool}' alt='Attribute Table '/></span><strong>Attribute Table</strong><br/>After selecting a layer from the Layers panel, the table containing the attribute information of the layers is opened and closed at the bottom of the application with the “Table” tool.`,
      position: 'right',
      title: 'Digitization',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.search-input',
      intro: `<span><img src='${QckSrch}' alt='Quick Search'/></span><strong>Quick Search</strong><br/>Quick search can search and query the attribute information of a layer with reference information. With this search result, the map screen zooms in on the searched data.`,
      position: 'bottom',
      title: 'Digitization',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.draw-box',
      intro: `<span><img src='${PointCoor}' alt='Point Coordinate Measurement'/></span><span><img src='${PointBtn}' alt='Point Button Image'/></span><strong>Point Coordinate Measurement</strong><br/>It allows obtaining coordinate information by adding points on the map screen and panorama. The coordinate of the point can be measured using floor, point cloud and multi-screen methods.`,
      position: 'bottom',
      title: 'Measurement Tools',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.draw-box',
      intro: `<span><img src='${LgntMsrmt}' alt='Length Measurement'/></span><span><img src='${LineBtn}' alt='Line Button Image'/></span></span><strong>Length Measurement</strong><br/>It allows measuring length in meters via the map screen and panorama. The length can be measured with floor, point cloud and multi-screen methods.`,
      position: 'bottom',
      title: 'Measurement Tools',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.draw-box',
      intro: `<span><img src='${AreaMsrmt}' alt='Area Measurement'/></span><span><img src='${PolyBtn}' alt='Polygon Button Image'/></span></span><strong>Area Measurement</strong><br/>It allows measuring area in square meters on the map screen. For length measurement in panorama, the area of the drawn shape can be measured using floor, point cloud and multi-screen methods.`,
      position: 'bottom',
      title: 'Measurement Tools',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.draw-box',
      intro: `<span><img src='${HghtMsr}' alt='Height Measurement'/></span><span><img src='${HeightMsrBtn}' alt='Height Measurement Button Image'/> </span><strong>Height Measurement</strong><br/>Height Measurement allows height measurement in meters via the panorama screen. Height measurement is used only with the floor and point cloud method.`,
      position: 'bottom',
      title: 'Measurement Tools',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.draw-box',
      intro: `<span><img src='${PerpLenght}' alt='Perpendicular Length Measurement'/></span><span><img src='${PerpLenghtBtn}' alt='Perpendicular Length Button Image'/></span><strong>Perpendicular Length Measurement</strong><br/>The floor method is used to measure perpendicular angle distance in meters on the panorama screen. In the floor method, a line perpendicular to a line on the floor is measured.`,
      position: 'bottom',
      title: 'Measurement Tools',
      tooltipClass: 'TooltipCustomClassToolbar',
    },

    {
      element: '.draw-box',
      intro: `<span><img src='${HghtMsr2}' alt='Height Measurement with Two Points'/></span><strong>Height Measurement with Two Points</strong><br/>With the point cloud method, when the top and bottom points of an object are marked respectively, the perpendicular height of the two points to the floor is measured.`,
      position: 'bottom',
      title: 'Measurement Tools',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.draw-box',
      intro: `<span><img src='${GridM}' alt='Grid Measurement'/></span><span><img src='${GridMsrBtn}' alt='Grid Measurement Button'/></span><strong>Grid Measurement</strong><br/>It enables measurement in all 3 axes while on the floor layer in the panorama.`,
      position: 'bottom',
      title: 'Measurement Tools',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.draw-box',
      intro: `<span><img src='${ClearBtn}' alt='Clear Button Image'/></span><strong>Clear</strong><br/>The “Clear” tool is used to clear the screen of any temporary operations performed on the map.`,
      position: 'bottom',
      title: 'Measurement Tools',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '#import-button-svg',
      intro: `<span><img src='${ImportLyr}' alt='Import Layer'/></span>The “Import Layer” tool is used to make an outside layer appear in the application.`,
      position: 'right',
      title: 'Import Layer',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.introjs-relativePosition',
      intro: `<span><img src='${ImportImg}' alt='Import Layer'/></span>Files in different formats (Geojson, Kml, ShapeFile, Gml) intended to be added are loaded and the EPSG coordinate code is entered.`,
      position: 'right',
      title: 'Import Layer',
      tooltipClass: 'TooltipCustomClass',
    },
    {
      element: '#print-svg',
      intro: `<span><img src='${Print}' alt='Print'/></span><br/><strong>Print</strong><br/>With the “Print” tool, the features desired to be included in the screen output are selected and then the screen is printed.`,
      position: 'right',
      title: 'Print',
      tooltipClass: 'TooltipCustomClassToolbar',
    },

    {
      element: '#export-button',
      intro: `<span><img src='${ExportLyr}' alt='Export Layer'/></span><br/><strong>Export Layer</strong><br/>With this tool, the layer is exported by selecting the desired format (Geojson, Kml, ShapeFile, Gml, Dxf) in the pop-up that appears on the screen and by entering the Projection EPSG code.  `,
      position: 'right',
      title: 'Export Layer',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
    {
      element: '.introjs-relativePosition',
      intro: `<img src='${ExprtPopUpImg}' alt='Export Pop-Up Image'/>After clicking the Export button, the layer is exported by selecting the desired format (Geojson, Kml, ShapeFile, Gml, Dxf) and EPSG coordinate system in the pop-up that appears on the screen.`,
      position: 'right',
      title: 'Export Layer',
      tooltipClass: 'TooltipCustomClass',
    },
    {
      element: '.right',
      intro: `The projection is changed with the “Projection Change” tool located in the lower right corner of the screen. With the “Go to Location” tool, the location can be viewed by typing the coordinates of the place you want to view on the map and panorama.`,
      position: 'left',
      title: 'Projection Change and Go to Location',
      tooltipClass: 'TooltipCustomClassToolbar',
    },
  ];

  const onExit = () => {
    setEnabled(false);
    dispatch(setButtonState(ButtonState.NONE));
    dispatch(setSidebarButton(false));
    dispatch(setTableButton(false));
  };
  return (
    <div>
      {buttonState === ButtonState.TOUR && (
        <>
          <Steps
            enabled={buttonState === 'TOUR' ? true : false}
            steps={steps}
            initialStep={initialStep}
            onExit={onExit}
          />
        </>
      )}
    </div>
  );
};


import { Vector } from 'ol/source';
import { GeoJSON } from 'ol/format';
import VectorLayer from 'ol/layer/Vector';
import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { MapContext } from '../MapContext';
import { Snap } from 'ol/interaction';
import axios from 'axios';
import VectorSource from 'ol/source/Vector';
import { useAppSelector } from '../../../../../state/hooks';
import { Project } from '../../../../../util/model/project';
import { RootState } from '../../../../../state/store';
import Modify from 'ol/interaction/Modify'
import { Toast } from 'primereact/toast';
import { DrawStateType } from '../../../../../util/model/drawState';
import { transformExtent } from 'ol/proj';

const map = (window as any).olMap
/* 
 * Bu component yeni feature oluştururken bu feature ı diğer featurelarla birleştirmek ve yakalamak için yazıldı.
 * Diğer featureların noktalarını görebilmesi için haritaya featureları wfs layer olarak ekliyoruz.
 *
*/

interface IProps {
    layername: string,
    visible: boolean,
    url_workspace: string
}

function WfsLayer(props: PropsWithChildren<IProps>) {

    const { layername, visible, url_workspace } = props;
    const map = useContext(MapContext);
    const t = useRef<VectorLayer<any> | null>(null);
    const [source, setSource] = useState<VectorSource<any>>(new VectorSource())
    const [isLoaded, setIsLoaded] = useState(false)
    const [snapInteraction, setSnapInteraction] = useState<any>(undefined)
    const drawState = useAppSelector(state => state.drawState.value)
    const buttonState = useAppSelector(state => state.buttonState.buttonState)

    const projects: Project[] = useAppSelector((state: RootState) => state.layer.projects)
    const toast = useRef<any>();

    //const openDigitization = useAppSelector((state) => state.digitization.openDigitization)

    useEffect(() => {
        const token = localStorage.getItem('token');
        const { REACT_APP_BASE_URL } = process.env

        // get map extent to download wfs data to use snap feature
        const currentExtent = map.getView().calculateExtent(map.getSize())
        // Extend the current extent by adding a buffer
        const buffer = 1000; // Adjust this value as needed
        const extendedExtent = [
        currentExtent[0] - buffer, // minX
        currentExtent[1] - buffer, // minY
        currentExtent[2] + buffer, // maxX
        currentExtent[3] + buffer  // maxY
        ];
        const extendedExtent4326 = extendedExtent;// transformExtent(extendedExtent, 'EPSG:3857', 'EPSG:4326');
        const url = REACT_APP_BASE_URL + `rest/v1/geoserver/${url_workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&outputFormat=application/json&srsname=EPSG:3857&typeName=` +
            layername + `&bbox=${extendedExtent4326.join(',')},EPSG:3857` + (token ? `&token=${token}` : '')

        const response = axios.get(url).then(response => {
            const type = response?.data.type

            const featureCollection = response?.data;

            // feature collection [] gelince hata veriyor, onu kontrol ediyoruz length ile
            if (type === "FeatureCollection" && featureCollection && !featureCollection.length) {
                const geojson = new GeoJSON();
                const features: any = geojson?.readFeatures(featureCollection)
                let vectorSource = new Vector();
                vectorSource.addFeatures(features)
                let vector = new VectorLayer({
                    source: vectorSource,
                    visible: visible/*true*/,
                    zIndex: 100000,
                    opacity: .001
                });

                setSource(vectorSource);
                setIsLoaded(true)

                t.current = vector;
                map.addLayer(vector);
            }
        }).catch((e: any) => {

            toast.current?.show({ severity: 'error', summary: 'Error Message', detail: "Digitization failed !", life: 3000 });

        })

        return () => {
            const intrcts : any= (window as any).olMap.getInteractions().getArray().filter((interaction:any)=>interaction instanceof Snap)
            const _snapInteraction : any= intrcts.find((interaction:any)=>interaction.getProperties().name === layername)
            if(_snapInteraction){
                // console.log("removeInteraction :",layername);
                (window as any).olMap.removeInteraction(_snapInteraction)
            }
            map.removeLayer(t.current!);
        }
    }, [drawState])

    useEffect(() => {
        const vectorLayer = t.current!;
        if (vectorLayer) {
            vectorLayer.setVisible(visible);
        }
    }, [visible])


    useEffect(() => {
        setTimeout(() => {
            const isSnapActive = (drawState.drawStateType !== DrawStateType.NONE || (window as any).buttonState === 'VERTEX' || (window as any).buttonState === 'ADD') && visible && isLoaded
            if (isSnapActive) {
                const snap = new Snap({
                    source: source,
                })
                const intrcts : any= (window as any).olMap.getInteractions().getArray().filter((interaction:any)=>interaction instanceof Snap)
                const control : any= intrcts.find((interaction:any)=>interaction.getProperties().name === layername)
                if(!control){
                    snap.setProperties({"name":layername})
                    map.addInteraction(snap)
                    setSnapInteraction(snap);
                }   
            } 
        }, 200);
            
        // else {
        //     if (snapInteraction) {
        //         map.removeInteraction(snapInteraction)
        //     }
        // }
            
        // return () => {
        //     const intrcts = (window as any).olMap.getInteractions().getArray()
        //     intrcts.forEach((interaction: any) => {
        //         if (interaction instanceof Snap /*|| interaction instanceof Modify*/) {
        //             map.removeInteraction(interaction)
        //             // console.log("SnapInteraction çıkış  ");
        //         }
        //     });
        // }
    }, [isLoaded, visible, drawState, buttonState])


    return (
        <>
            <Toast ref={toast} position='bottom-center' style={{ zIndex: '9 !important' }} />
        </>
    )
}

export default WfsLayer
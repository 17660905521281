import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useContext, useEffect, useState } from "react";
import { MapContext } from '../../../map-container/map/MapContext';
import { useAppSelector } from '../../../../../state/hooks';
import { Modify, Translate } from 'ol/interaction';
import Collection from 'ol/Collection';
import { GeoJSON } from 'ol/format';
import WFS from 'ol/format/WFS';
import GML from 'ol/format/GML';
import Feature from 'ol/Feature';
import { MultiLineString, MultiPolygon, MultiPoint } from 'ol/geom';
import { DigitizationRestApi } from "../../../../../util/restapi/digitization";
import { setFrom, setPanoramaTransactStatus } from '../../../../../state/features/digitizationSlice';
import { DigiCallbackContext } from '../DigiCallbackContextProvider';
import { FeatureRestApi } from '../../../../../util/restapi/features';
import { setButtonState } from '../../../../../state/features/buttonStateSlice';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import { setSelectedFeature } from '../../../../../state/features/selectedFeatureSlice';
import { setFeatures } from '../../../../../state/features/tableSlice';
import calculateResult from './calculateResult';

interface ModifyEndEvent {
    features: any//Collection;
    mapBrowserEvent: {
      coordinate: number[];
      originalEvent: PointerEvent;
      [key: string]: any;
    };
    target: Modify;
    type: string;
  }
  
export const MoveFeature = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [selectedFeature]: any = useAppSelector(state => state.selectedFeature.feature)
    const [translate, setTranslate] = useState<Translate>(new Translate())
    const [featureForTranslate, setFeatureForTranslate] = useState<any>()
    const services: any = useAppSelector(state => state.layer.services)

    const map = useContext(MapContext)
    const [modifyChangeEvent, setModifyChangeEvent] = useState<any>()
    let layerAndWorkspace : any ;
    const digiCallback = useContext(DigiCallbackContext);
    const [calcFields, setCalcFields] = useState<any>([])
    const selectedLayer: any = useAppSelector(state => state.layer.selectedLayer);

    useEffect(() => {
        const content = map.getTargetElement();

        if(modifyChangeEvent){
             content.removeEventListener('contextmenu', rightClickHandler)
             content.addEventListener('contextmenu',rightClickHandler);
  
            if(translate){
                translate.un('translateend', translateEnd);
          //      map.removeInteraction(translate);
            }
        }


        return () => {
        //    map.removeInteraction(translate);
            content.removeEventListener('contextmenu', rightClickHandler)
        
            const intrcts = (window as any).olMap.getInteractions().getArray()
            intrcts.forEach((interaction: any) => {
                if (interaction instanceof Translate) {
                    (window as any).olMap.removeInteraction(interaction)
                }
            });
        }

    }, [modifyChangeEvent]);

    useEffect(() => {
        const intrcts = (window as any).olMap.getInteractions().getArray()
        intrcts.forEach((interaction :any) => {
            if(interaction instanceof Translate){
                (window as any).olMap.removeInteraction(interaction)
            }
        });

        setFeatureForTranslate(selectedFeature)
        addTranslateInteraction()
    }, [selectedFeature]);


    const rightClickHandler = (event: MouseEvent) => {
        const x = event.clientX;
        const y = event.clientY;
        const coordinateFromPixel = map.getCoordinateFromPixel([x, y]);
        const feature = modifyChangeEvent;
        const olGeoJson = new GeoJSON();
        //  console.log("calcFields :", calcFields);
        // console.log("changedFields :", changedFields);
        let propertyOfFeatures: any[] = []
        const filteredFields = selectedLayer.fields.filter((field: any) => field.type !== 'bbox' && field.type != 'geom' && field.type != 'Geom' && field.type !== 'Id' && field.type !== 'CreatedBy' && field.type !== 'CreatedDate' && field.type !== 'TouchBy' && field.type !== 'TouchDate')
        filteredFields.map((field: any) => {
            if(calcFields.hasOwnProperty(keyOfTypes[field.type])){
                if(calcFields[keyOfTypes[field.type]]){
                    propertyOfFeatures.push({ [field.name]: calcFields[keyOfTypes[field.type]] })
                }
                
            }
        })
        
        const properties = Object.assign({}, ...propertyOfFeatures);
        
        const geoJsonFinal:any = olGeoJson.writeFeatureObject(feature);
        delete geoJsonFinal.properties.bbox;

        const isModify = window.confirm("Save Moved Feature? ");
        if (isModify) {

            let newFeature = new Feature()
            if(propertyOfFeatures.length){
                newFeature.setProperties(properties)
            }else{
                newFeature.setProperties(geoJsonFinal.properties)
            }
            newFeature.setGeometryName('geom')

            const type = geoJsonFinal.geometry.type

            let newGeom :any 

            if(type ==="MultiPoint"){

                newGeom = new MultiPoint(geoJsonFinal.geometry.coordinates)
                
            }else if(type ==="MultiLineString"){

                newGeom = new MultiLineString(geoJsonFinal.geometry.coordinates)
            }else if(type ==="MultiPolygon"){

                newGeom = new MultiPolygon(geoJsonFinal.geometry.coordinates)
            }
      
            newFeature.setGeometry(newGeom)
            newFeature.setId(selectedFeature.id)
            // console.log("newFeature :",newFeature);
            // console.log("selectedFeature.id :",selectedFeature.id);
            
            layerAndWorkspace = getLayerAndService(selectedFeature.id, services)

            let formatWFS :any= new WFS();

            let formatGML = new GML({
                featureNS: layerAndWorkspace.service.workspace,
                featureType: layerAndWorkspace.layer.name,
                srsName: 'EPSG:3857',
                hasZ: true,
            });
    
            let xs = new XMLSerializer();
    
    
            let node: any;

            node = formatWFS.writeTransaction(null, [newFeature], null, formatGML);

            let payload = xs.serializeToString(node)

            newFeature.unset('bbox', true)
            newFeature.unset('key', true)
            newFeature.unset('label', true)



            DigitizationRestApi.digiAddFeature(payload, layerAndWorkspace.service.url).then((res: any) => {
                if (res) {
                    // dispatch(setToastMessageStatus(true))
                    dispatch(setPanoramaTransactStatus(false))
                }

                const featureId = res.split('.')[1]
                // if (typeof (window as any).addDigiCallback === 'function') {
                //     (window as any).addDigiCallback(featureId)
                // }
                if (typeof digiCallback.callbackFunction === 'function') {
                    digiCallback.callbackFunction(featureId)
                }

                
                let formatWFS :any= new WFS();
                let xs = new XMLSerializer();
  
                //  const deleteFeature = new GeoJSON().readFeature(selectedFeature)
                 //@ts-ignore
                //  node = formatWFS.writeTransaction(null, null, [deleteFeature], formatGML);
          
                //   payload = xs.serializeToString(node)
                 
                //   DigitizationRestApi.digiAddFeature(payload, layerAndWorkspace.service.url).then((res: any) => {
                    
                    // if (res) {
                    //     // dispatch(setToastMessageStatus(true))
                    //     dispatch(setPanoramaTransactStatus(false))
                    // }
                    // // insert yapiliyorsa geoservera istek yapip featureId yi alacak o id ile attachment istegi yapacak
  
                    // const featureId = res.split('.')[1]
                    // if (typeof digiCallback.callbackFunction === 'function') {
                    //     digiCallback.callbackFunction(featureId)
                    // }

                // FeatureRestApi.getFeatures(layerAndWorkspace.service.url, layerAndWorkspace.layer.name).then((res: any) => {
               //     if (res) {
                      
               //         dispatch(setFeatures(res.data.features));

                        // map update 
                        const layersOfMap = map.getLayers();
                
                        layersOfMap.forEach((layer: any) => {
                            let source: any = layer.getSource();
                            if (source.updateParams != undefined)
                                source.updateParams({ 'time': Date.now() });
                        });
                        
                        dispatch(setPanoramaTransactStatus(undefined))
                        dispatch(setButtonState(ButtonState.NONE))
                        dispatch(setSelectedFeature([]))
                        
                        map.updateSize();
                        map.render();
               //     }
                // })

                const intrcts = (window as any).olMap.getInteractions().getArray()
                intrcts.forEach((interaction: any) => {
                    if (interaction instanceof Translate) {
                        (window as any).olMap.removeInteraction(interaction)
                    }
                });
            // })
            }).catch((err:any)=>{
                
            })
        } else{
            dispatch(setPanoramaTransactStatus(undefined))
            dispatch(setButtonState(ButtonState.NONE))
            dispatch(setSelectedFeature([]))
            const intrcts = (window as any).olMap.getInteractions().getArray()
            intrcts.forEach((interaction: any) => {
                if (interaction instanceof Translate) {
                    (window as any).olMap.removeInteraction(interaction)
                }
            });
        }

      }


      const getLayerAndService = (featureId: string, services: any) => {
        let service;
        let layer;
        const layerName = featureId.split('.')[0]
    
        for (const item of services) {
          for (const testLayer of item.layers) {
            if (testLayer.name === layerName) {
              layer = testLayer
              service = item
            }
          }
        }
        const obj = {
          layer: layer,
          service: service
        }
        return obj
      }

    const modifyEvent= (event:any): void => {
        if (event) {
            setModifyChangeEvent(event)
        } else {
            setModifyChangeEvent(undefined)
        }
      }
    

    const addTranslateInteraction = (): void => {
        const collection = new Collection([featureForTranslate])

        setTranslate(new Translate({
            features: collection
          }))

        map.addInteraction(translate);
        translate.on('translateend', translateEnd);
    }


    const translateEnd = async (event: ModifyEndEvent) => {
        const feature = event.features.item(0);
        dispatch(setFrom("Map"))

        const _calcFields = await calculateResult(feature, "Map");
        // console.log("_calcFields : ",_calcFields);
        
        setCalcFields(_calcFields);
        if (!feature) {
            modifyEvent(false);
            return;
        }
        modifyEvent(feature);
    }
    const keyOfTypes :any = {
        "Length":"length",
        "Start_Point":"start_Point",
        "End_Point":"End_Point",
        "Middle_Point":"Middle_Point",
        "Area":"Area",
        "Location":"location",
        "Start_Point(X)":"start_Point_X",
        "End_Point(X)":"End_Point_X",
        "Middle_Point(X)":"Middle_Point_X",
        "Location(X)":"locationX",
        "Start_Point(Y)":"start_Point_Y",
        "End_Point(Y)":"End_Point_Y",
        "Middle_Point(Y)":"Middle_Point_Y",
        "Location(Y)":"locationY",
        "Start_Point(Z)":"start_Point_Z",
        "End_Point(Z)":"End_Point_Z",
        "Middle_Point(Z)":"Middle_Point_Z",
        "Location(Z)":"locationZ",
        // "Height" : 'Height',
        "Perimeter" : 'Perimeter'
    }
    return (
        <>

        </>
    )
};
import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  showLoading: false,
};

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setShowLoading: (state: any, action) => {
      state.showLoading = action.payload;
    },
  },
});

export const { setShowLoading } = loadingSlice.actions;

export default loadingSlice.reducer;

import VectorSource from 'ol/source/Vector';
import { useEffect } from 'react'

interface Props {
    source: VectorSource<any>;
}

export const ClearAll = (props: Props) => {
    useEffect(() => {
        props.source.clear();

    }, [])

    return (
        <>
        </>
    )
}

import { createSlice } from "@reduxjs/toolkit";

interface IFeatureState {
  feature: [];
  extent: any;
  ctrlDown: boolean;
  vertexFeature: [];
}

const initialState: IFeatureState = {
  feature: [],
  extent: false,
  ctrlDown: false,
  vertexFeature: [],
};

export const selectedFeatureSlice = createSlice({
  name: "selectedFeature",
  initialState,
  reducers: {
    setSelectedFeature: (state: any, action) => {
      state.feature = action.payload;
    },
    setVertexFeature: (state: any, action) => {
      state.vertexFeature = action.payload;
    },
    setFeatureExtent: (state: any, action) => {
      state.extent = action.payload;
    },
    setCtrlDown: (state: any, action) => {
      state.ctrlDown = action.payload;
    },
  },
});

export const {
  setSelectedFeature,
  setFeatureExtent,
  setCtrlDown,
  setVertexFeature,
} = selectedFeatureSlice.actions;

export default selectedFeatureSlice.reducer;

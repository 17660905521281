
import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env

const getToken = () => {
    return localStorage.getItem('token')
}

async function getBasemaps() {
    return axios.get(REACT_APP_BASE_URL + 'rest/v1/basemap/', { params: { token: getToken() } });
}

export const BasemapRestApi = {
    getBasemaps
}
import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env

const getToken = () => {
    return localStorage.getItem('token')
}

const getPointCloudData = async (lat: any, lon: any, radius: number) => {
    window.open(`${REACT_APP_BASE_URL}rest/v1/export/las?wkt=Point(${lat} ${lon})&radius=${radius}&token=${getToken()}`);
}

export const PointCloudRestApi = {
    getPointCloudData
}


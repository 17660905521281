import './TakeParallel.scss';
import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from "../../../../../state/hooks";
import WKT from 'ol/format/WKT';
import GeoJSON from 'ol/format/GeoJSON';
import { useDispatch } from 'react-redux';
import { setWktGdhForParallel } from '../../../../../state/features/panoramaSlice';
import { Dialog } from 'primereact/dialog';
import { setButtonState } from '../../../../../state/features/buttonStateSlice';
import { ButtonState } from '../../../../shared/button-state/ButtonState';
import { Button } from 'primereact/button';
import LineString from 'ol/geom/LineString';
import { fromLonLat } from 'ol/proj';
import cleanCoords from '@turf/clean-coords';
import lineOffset from '@turf/line-offset';
import MultiLineString from 'ol/geom/MultiLineString';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Feature from 'ol/Feature';
import { Geometry } from 'ol/geom';
import Fill from 'ol/style/Fill';
import CircleStyle from 'ol/style/Circle';
import { DigiDrawPoint } from '../digi-draw/DigiDrawPoint';
import { DigiDrawLinestring } from '../digi-draw/DigiDrawLinestring';
import { DigiDrawPolygon } from '../digi-draw/DigiDrawPolygon';


export const TakeParallel = () => {
  const [selectedFeature]: any = useAppSelector(state => state.selectedFeature.feature)

  const [isOffset, setIsOffset] = useState<any>()
  const [cloneGeom, setCloneGeom] = useState<any>()
  const [offsetVal, setOffsetVal] = useState<any>(0)
  const [translateX, setTranslateX] = useState<any>(0)
  const [translateY, setTranslateY] = useState<any>(0)
  const [translateZ, setTranslateZ] = useState<any>(0)
  const [offsetTest, setOffsetTest] = useState<number>(0)
  const [translateXTest, setTranslateXTest] = useState<number>(0)
  const [translateYTest, setTranslateYTest] = useState<number>(0)
  const [translateZTest, setTranslateZTest] = useState<number>(0)

  
  const dispatch = useDispatch();
  let _geojson:any;

  const olMap = (window as any).olMap
  let wktGeom :any 
  let wkt = new WKT()
  
  const [vector, setVector] = useState<VectorLayer<any>>(new VectorLayer())

  const [source, setSource] = useState<any>()

  const wktGdhForParallel = useAppSelector(state => state.panorama.wktGdhForParallel);
  const panoramaType = useAppSelector(state => state.panorama.type);
  const [geoType, setGeomType] = useState<string>()
  const [digi, setDigi] = useState<boolean>()
  const [visible, setVisible] = useState<boolean>(true)
  const [dotPutOffset, setDotPutOffset] = useState<boolean>(false)
  const [dotPutTranslateX, setDotPutTranslateX] = useState<boolean>(false)
  const [dotPutTranslateY, setDotPutTranslateY] = useState<boolean>(false)
  const [dotPutTranslateZ, setDotPutTranslateZ] = useState<boolean>(false)

  
  
  useEffect(() => {
    const vector = new VectorLayer({
      source: new VectorSource(),
      zIndex: 10000000000000,
      style: new Style({
        // fill: new Fill({
        //   color: '#3ea1f1',
        // }),
        stroke: new Stroke({
          color: '#3ea1f1',
          width: 2,
        }),
        image: new CircleStyle({
          radius: 5,
          // fill: new Fill({
          //   color: '#3ea1f1',
          // }),
          stroke: new Stroke({
            color: '#3ea1f1',
            width: 2,
          }),
        }),
      }),
      properties:{
          alias: "shadow"
      }
    });
    olMap?.addLayer(vector);
    setSource(vector.getSource())
    setVector(vector)

    if(selectedFeature){
      const geomType = selectedFeature.geometry.type
      setGeomType(geomType)
      let isOffset = geomType === 'MultiPoint' || geomType === 'Point'
      setIsOffset(isOffset)
      
      const olGeoJson = new GeoJSON()
      const feature:any = olGeoJson.readFeature(selectedFeature)
      // console.log("feature.getGeometry() : ",feature.getGeometry());
      setCloneGeom(feature.getGeometry().clone())
      feature.getGeometry().transform('EPSG:3857', 'EPSG:4326')
      wktGeom = wkt.writeFeature(feature);
      
      dispatch(setWktGdhForParallel(wktGeom));
    }

    return () => {
      dispatch(setWktGdhForParallel(undefined))
      olMap?.removeLayer(vector)
    }

  }, [selectedFeature])

  const offsetChange = (e:any)=>{
    let count = 0;
    if (e) {
      for (let i = 0; i < e.length; i++) {
        if (e[i] === '.') {
          count++;
        }
      }

      if (count == 1 && e.endsWith('.')) {
        setDotPutOffset(true)
      }
      else {
        setDotPutOffset(false)
      }

      setOffsetVal(parseFloat(e))
    }
    else {
      setOffsetVal(parseFloat('0'))
    }
  }
  const onOffsetWheel = (e: any) => {
    if (Math.sign(e.deltaY) === -1) {
      const newValue = parseFloat(e.target.value) + 0.1
      setOffsetVal(parseFloat(newValue.toFixed(2)))
    }
    else if (Math.sign(e.deltaY) === 1) {
      const newValue = parseFloat(e.target.value) - 0.1
      setOffsetVal(parseFloat(newValue.toFixed(2)))
    }
  }
  const tranlateXChange = (e: any) => {
    let count = 0;
    if (e) {
      for (let i = 0; i < e.length; i++) {
        if (e[i] === '.') {
          count++;
        }
      }

      if (count == 1 && e.endsWith('.')) {
        setDotPutTranslateX(true)
      }
      else {
        setDotPutTranslateX(false)
      }

      setTranslateX(parseFloat(e))
    }
    else {
      setTranslateX(parseFloat('0'))
    }
  }
  const onTranslateXWheel = (e: any) => {
    if (Math.sign(e.deltaY) === -1) {
      const newValue = parseFloat(e.target.value) + 0.1
      setTranslateX(parseFloat(newValue.toFixed(2)))
    }
    else if (Math.sign(e.deltaY) === 1) {
      const newValue = parseFloat(e.target.value) - 0.1
      setTranslateX(parseFloat(newValue.toFixed(2)))
    }
  }
  const tranlateYChange = (e: any) => {
    let count = 0;
    if (e) {
      for (let i = 0; i < e.length; i++) {
        if (e[i] === '.') {
          count++;
        }
      }

      if (count == 1 && e.endsWith('.')) {
        setDotPutTranslateY(true)
      }
      else {
        setDotPutTranslateY(false)
      }

      setTranslateY(parseFloat(e))
    }
    else {
      setTranslateY(parseFloat('0'))
    }
  }
  const onTranslateYWheel = (e: any) => {
    if (Math.sign(e.deltaY) === -1) {
      const newValue = parseFloat(e.target.value) + 0.1
      setTranslateY(parseFloat(newValue.toFixed(2)))
    }
    else if (Math.sign(e.deltaY) === 1) {
      const newValue = parseFloat(e.target.value) - 0.1
      setTranslateY(parseFloat(newValue.toFixed(2)))
    }
  }
  const tranlateZChange = (e: any) => {
    let count = 0;
    if (e) {
      for (let i = 0; i < e.length; i++) {
        if (e[i] === '.') {
          count++;
        }
      }

      if (count == 1 && e.endsWith('.')) {
        setDotPutTranslateZ(true)
      }
      else {
        setDotPutTranslateZ(false)
      }

      setTranslateZ(parseFloat(e))
    }
    else {
      setTranslateZ(parseFloat('0'))
    }
  }
  const onTranslateZWheel = (e: any) => {
    if (Math.sign(e.deltaY) === -1) {
      const newValue = parseFloat(e.target.value) + 0.1
      setTranslateZ(parseFloat(newValue.toFixed(2)))
    }
    else if (Math.sign(e.deltaY) === 1) {
      const newValue = parseFloat(e.target.value) - 0.1
      setTranslateZ(parseFloat(newValue.toFixed(2)))
    }
  }

  useEffect(() => {
    if ((translateX || translateX == 0) && (translateY || translateY == 0) && (translateZ || translateZ == 0) && (offsetVal || offsetVal == 0) && cloneGeom) {

      update()
    }
  }, [translateX, translateY, translateZ, offsetVal])
  const newParallelGeom = (temp:Geometry)=>{
    const feature:any = new Feature()
    feature.setGeometry(temp);
    setVector(vector)
    const layer = olMap?.getLayers().getArray().find((el:any)=>el.getProperties().alias === 'shadow');
    layer.getSource()?.clear()
    layer.getSource()?.addFeature(feature)
  }
  const getGeojsonInstance = () => {
    if (_geojson === undefined) {
        _geojson = new GeoJSON();
    }
    return _geojson;
  }
  const update = () => {
    let temp = cloneGeom.clone();
    
    if (offsetVal !== 0) {
      const newLineString = new LineString(temp.getLineString(0).getCoordinates());
      let temp4326 :any = newLineString.clone();
      temp4326 = temp4326.transform('EPSG:3857', 'EPSG:4326');
      const [lon, lat] = temp4326.getFirstCoordinate();
      const newCoord = (window as any).AnkaPanAPI.Utils.destinationPoint(lat, lon, offsetVal * 1, 90);

      const diffLine = new LineString([fromLonLat([lon, lat]), fromLonLat([newCoord.lon, newCoord.lat])]);
      let newDist = diffLine.getLength();
      if (offsetVal < 0) {
        newDist = newDist * -1;
      }
      const lineGeojson: { coordinates: any[], type: string } = JSON.parse(getGeojsonInstance().writeGeometry(newLineString));
      const newCoordinates = cleanCoords(lineGeojson);
      const newGeom = lineOffset(newCoordinates, newDist, { units: 'degrees' });

      if (typeof newCoordinates.coordinates[0][2] === 'number') {
        newGeom.geometry.coordinates.forEach((line:any, i:any) => {
          line[2] = newCoordinates.coordinates[i][2];
        });
      }

      if (newGeom.geometry.type === 'LineString') {
        temp = new MultiLineString([newGeom.geometry.coordinates]);
      } else if (newGeom.geometry.type === 'MultiLineString') {
        temp = new MultiLineString(newGeom.geometry.coordinates);
      } else {
        throw new Error('newGeom.geometry.type is not known: ' + newGeom.geometry.type);
      }
    }

    temp.translate(translateX, translateY);

    const tempGeomLayout: string = temp.getLayout();
    if (tempGeomLayout.length === 3 && typeof translateZ === 'number' && translateZ !== 0) {
      const tempCoordinates: number[] = temp.flatCoordinates;
      tempCoordinates.forEach((coord, index) => {
        if ((index + 1) % 3 === 0) {
          tempCoordinates[index] = coord + translateZ;
        }
      })
      temp.changed();
    }

    newParallelGeom(temp);

    const panoTemp = temp.clone();
    panoTemp.transform('EPSG:3857', 'EPSG:4326');
    const panoCoordinates = [];

    for (const coordinate of panoTemp.getCoordinates()[0]) {
      panoCoordinates.push({ lon: coordinate[0], lat: coordinate[1], alt: coordinate[2] });
    }
    
    const wktGeomForParallel = wkt.writeGeometry(panoTemp);
    
    dispatch(setWktGdhForParallel(undefined))

    dispatch(setWktGdhForParallel(wktGeomForParallel))

    newParallelGeom(temp)
  }

  const onClickParallel = () => { 
    const geom :any = wktGdhForParallel;
    setDigi(true)
    setVisible(false)

  }
  return (
       <div className='TakeParallel'>
        <Dialog
          position='top-left'
          className='take-dialog'
          header='Parallel For Geometry'
          visible={visible}
          onHide={() =>{ 
             dispatch(setButtonState(ButtonState.NONE))
          }}
        >
        <div className="body">
            { !isOffset && <div className="label-group">
                <label className="digitization-input-name" htmlFor="offset"> Offset </label>
            <input id="offset" className="digitization-input" title="Offset" step="0.1" type="number" onInput={(e: any) => { offsetChange(e.target.value) }}
              value={offsetVal.toString() + (dotPutOffset ? '.' : '')}
            // onWheel={onOffsetWheel}
                />
            </div> }

            <div className="label-group">
                <label className="digitization-input-name" htmlFor="x"> Taşı X </label>
            <input id="x" className="digitization-input" title="Taşı X" type="number" step="0.1" onInput={(e: any) => { tranlateXChange(e.target.value) }}
              // onWheel={onTranslateXWheel}
              value={translateX.toString() + (dotPutTranslateX ? '.' : '')}
                />
            </div>
            <div className="label-group">
                <label className="digitization-input-name" htmlFor="y"> Taşı Y </label>
            <input id="y" className="digitization-input" title="Taşı Y" type="number" step="0.1" onInput={(e: any) => { tranlateYChange(e.target.value) }}
              // onWheel={onTranslateYWheel}
              value={translateY.toString() + (dotPutTranslateY ? '.' : '')} />
            </div>
            <div className="label-group">
                <label className="digitization-input-name" htmlFor="z"> Taşı Z </label>
            <input id="z" className="digitization-input" title="Taşı Z" type="number" step="0.1" onInput={(e: any) => { tranlateZChange(e.target.value) }}
              // onWheel={onTranslateZWheel}
              value={translateZ.toString() + (dotPutTranslateZ ? '.' : '')} />
            </div>
        </div>
        
        <div className="footer">
          <div className='buttons'>
              <Button
                disabled={
                  offsetVal === 0 && 
                  translateX === 0 &&
                  translateY === 0 &&
                  translateZ === 0 
                }
                onClick={() => onClickParallel()} label='Parallel' type="submit" className='save-button'>
              </Button>
          </div>
        </div>
        </Dialog>
        {digi && (geoType === 'MultiPointZ' || geoType === 'MultiPoint') &&  panoramaType === 'Floor' && <DigiDrawPoint source={source} />}
        {digi && (geoType === 'MultiLineStringZ' || geoType === 'MultiLineString') &&  panoramaType === 'Floor' && <DigiDrawLinestring source={source} />}
        {digi && (geoType === 'MultiPolygonZ' || geoType === 'MultiPolygon') &&  panoramaType === 'Floor' && <DigiDrawPolygon source={source} />}
      </div>
  )
}
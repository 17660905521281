import axios from 'axios';

const { REACT_APP_BASE_URL } = process.env

async function getLegends() {
    return axios.get(`${REACT_APP_BASE_URL}geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&HEIGHT=10&LAYER=beyoglu_yol_usr&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:14;bgColor:0xFFFFEE;dpi:180`
        , { responseType: 'arraybuffer' });
}

export const LegendRestApi = {
    getLegends
}


import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedLayerInfo: [],
}

export const infoSlice = createSlice({
    name: 'layer',
    initialState,
    reducers: {
        setSelectedLayerInfo: (state: any, action) => {
            state.selectedLayerInfo = action.payload;
        },
    }
})

export const { setSelectedLayerInfo } = infoSlice.actions

export default infoSlice.reducer
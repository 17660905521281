import { createSlice } from '@reduxjs/toolkit';
import { DrawStateType } from '../../util/model/drawState';

const initialState: any = {
    value: {
        drawStateType: DrawStateType.NONE,
    }
}

export const drawStateSlice = createSlice({
    name: 'drawState',
    initialState,
    reducers: {
        setDrawState: (state: any, action) => {
            state.value = action.payload;
        }
    }
})

export const { setDrawState } = drawStateSlice.actions

export default drawStateSlice.reducer
import Feature from "ol/Feature";
import Draw from "ol/interaction/Draw";
import VectorLayer from "ol/layer/Vector";
import CircleStyle from "ol/style/Circle";
import { useContext, useEffect, useState } from "react"
import { MapContext } from "../../../map-container/map/MapContext";
import { useDispatch } from "react-redux";
import { Fill, Stroke, Style } from "ol/style";
import { ButtonState } from "../../../../shared/button-state/ButtonState";
import { useAppSelector } from "../../../../../state/hooks";
import { MultiPolygon } from "ol/geom";
import { Mode, TransactEdit } from "../TransactEdit";
import { DigiPopup } from "../digi-popup/DigiPopup";
import { setDigiPopupVisibility, setFrom, setGeomEditStatus } from "../../../../../state/features/digitizationSlice";
import { useTranslation } from "react-i18next";

interface Props {
    source: any
}

export const DrawHole = (props: Props) => {
    const dispatch = useDispatch();
    const olMap = useContext(MapContext);
    const { t } = useTranslation();

    const [newFeatureObj, setNewFeatureObj] = useState<any>()
    const [vector, setVector] = useState<VectorLayer<any>>(new VectorLayer())

    const selectedFeature: any = useAppSelector(state => state.selectedFeature.feature);
    const popupSaveStatus: any = useAppSelector(state => state.digitization.popupSaveStatus)
    const geomEditStatus: any = useAppSelector(state => state.digitization.geomEditStatus)
    const panoramaTransactStatus = useAppSelector(state => state.digitization.panoramaTransactStatus)
    const buttonState: any = useAppSelector(state => state.buttonState.buttonState)
    const digiPopupVisibility = useAppSelector((state) => state.digitization.digiPopupVisibility);
    const map = useContext(MapContext);

    const styles = [
        new Style({
            fill: new Fill({
                color: 'transparent',
            }),
            stroke: new Stroke({
                color: 'white',
                width: 6,
            }),
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: 'white',
                }),
            }),
        }),
    ];


    useEffect(() => {
        if (selectedFeature.length > 0) {
            drawFunc('Polygon', olMap, vector, 'hole')
        }
    }, [selectedFeature])

    useEffect(() => {
        if(digiPopupVisibility){
            const intrcts = (window as any).olMap.getInteractions().getArray()
            intrcts.forEach((interaction: any) => {
                if (interaction instanceof Draw) {
                    map.removeInteraction(interaction)
                }
            });
        }
    }, [digiPopupVisibility]);

    const drawFunc = (type: any, map: any, vector_layer: any, hole: any) => {
        //Draw feature
        let draw = new Draw({
            type: 'Polygon',
            stopClick: true,
            source: props.source,
            style: new Style({
                fill: new Fill({
                    color: 'pink',
                }),
                stroke: new Stroke({
                    color: 'yellow',
                    width: 2,
                }),
                image: new CircleStyle({
                    radius: 5,
                    fill: new Fill({
                        color: 'green',
                    }),
                }),
            }),
            // finishCondition: never,
            condition: function (e: any) {
                //sadece solla çizim yapıyor ve sağla bitiriyor
                if (e.originalEvent.buttons === 1) {
                    return true;
                } else if (e.originalEvent.buttons === 2) {
                    draw.finishDrawing();
                    return false;
                }
                else {
                    return false;
                }
            },
        });

        olMap.addInteraction(draw);

        draw.on('drawend', (event: any) => {
            olMap.removeInteraction(draw)
            event.feature.setStyle(styles)
            onGeomChange(event)
        });

        const content = map.getTargetElement();
        content.addEventListener('contextmenu', (event: MouseEvent) => {
            olMap.removeInteraction(draw);
        });

        return () => {
            map.removeInteraction(draw)
            props.source.clear();
        }
    }

    /*
This function will called when ever there will be a change in geometry like increase in length, area, position,
*/
    const onGeomChange = (e: any) => {
        const holeFeature = e.feature.getGeometry()?.getCoordinates()[0]

        setTimeout(() => {
            const newFeatureCoord = selectedFeature[0].geometry.coordinates[0].concat([holeFeature])

            const polygon = new MultiPolygon([newFeatureCoord])
            const newFeature: any = new Feature({
                geometry: polygon,
            })

            newFeature.setId(selectedFeature[0].id)
            newFeature.setProperties(selectedFeature[0].properties)
            newFeature.setGeometryName('geometry')
            newFeature.setGeometry(newFeature.getGeometry())

            setNewFeatureObj(newFeature)

            dispatch(setGeomEditStatus(true))
            dispatch(setDigiPopupVisibility(true))
            dispatch(setFrom("Map"))
        }, 500);
    }

    return (
        <>
            {digiPopupVisibility && buttonState === ButtonState.HOLE && <DigiPopup title='Veri Düzenle' content={`${t('CONFIRMATION.Save')}`} from='Map' mode={Mode.UPDATE} />}

            {!panoramaTransactStatus && geomEditStatus && popupSaveStatus && buttonState === ButtonState.HOLE &&
                <TransactEdit
                    coordinates={newFeatureObj.getGeometry().getCoordinates()}
                    feature={newFeatureObj}
                    mode={Mode.UPDATE}
                    type={'MultiPolygon'}
                    source={props.source}
                />}

        </>
    )
}
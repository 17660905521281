import { useContext, useEffect, useState } from 'react'
import { MapContext } from '../MapContext'
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import { DrawPoint } from './DrawPoint';
import { useAppSelector } from '../../../../../state/hooks';
import { DrawLinestring } from './DrawLinestring';
import { ClearAll } from './ClearAll';
import { DrawPolygon } from './DrawPolygon';
import { DrawStateType } from '../../../../../util/model/drawState';
import GeoJSON from 'ol/format/GeoJSON';
import CircleStyle from 'ol/style/Circle';
import Text from 'ol/style/Text';
import { Snap } from 'ol/interaction';
import { ButtonState } from '../../../../shared/button-state/ButtonState';

export const Draw = () => {

  const map = useContext(MapContext);
  const drawState = useAppSelector(state => state.drawState.value);
  const panoramaCoordinates = useAppSelector((state) => state.coordinates.panoCoordinates);
  const panoramaTransactStatus = useAppSelector(state => state.digitization.panoramaTransactStatus)

  const [vector, setVector] = useState<VectorLayer<any>>(new VectorLayer())
  const [source, setSource] = useState<VectorSource<any>>(new VectorSource())
  const buttonState: any = useAppSelector(state => state.buttonState.buttonState)

  useEffect(() => {
    const source = new VectorSource({ wrapX: false });
    const vector = new VectorLayer({
      source: source,
      zIndex: 100000,
      style: new Style({
        fill: new Fill({
          color: '#64b5f640',
        }),
        stroke: new Stroke({
          color: '#3ea1f1',
          width: 3,
        }),
        image: new CircleStyle({
          radius: 5,
          fill: new Fill({
            color: '#3ea1f1',
          }),
        }),
      }),
      properties:{
        alias: 'draws'
      }
    })

    const snap = new Snap({
      source: source
    })
    map.addInteraction(snap)

    map.addLayer(vector);
    setVector(vector)
    setSource(source)
    return () => {
      map.removeLayer(vector)
    }
  }, [])

  useEffect(() => {
    if (panoramaCoordinates.type) {
      const feature: any = new GeoJSON().readFeatures(panoramaCoordinates.feature.features[0]);
      if(buttonState === ButtonState.ADD){
        feature[0].setProperties({'alias': 'draws'})
      }
      source.addFeature(feature[0])
      setTextAfterDrawEnd(feature[0], panoramaCoordinates.text)
    }
  }, [panoramaCoordinates])

  const setTextAfterDrawEnd = (feature: any, text: string) => {
    feature.setStyle(new Style({
      fill: new Fill({
        color: '#64b5f640',
      }),
      stroke: new Stroke({
        color: '#3ea1f1',
        width: 3,
      }),
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: '#3ea1f1',
        }),
      }),
      text: new Text({
        text: `${text}`,
        font: "bold 15px 'Open Sans', 'Arial Unicode MS', 'sans-serif'",
        placement: 'point',
        offsetY: -15,
        fill: new Fill({
          color: 'white',
        }),
        stroke: new Stroke({
          color: 'black',
          width: 3,
        }),
      }),
    }));
  }

  useEffect(() => {
    if (panoramaTransactStatus) {
      // console.log("source.",source);
     /*  if(buttonState !== ButtonState.MOVE || buttonState !== ButtonState.VERTEX){
        const ffs: any = source.getFeatures();
        source.removeFeature(ffs[ffs.length-1]);
        // source.clear();
      } */
      const layer_:any = map.getLayers().getArray().find((el:any)=> el.getProperties().alias === 'draws')
      const draws:any = [...layer_.getSource().getFeatures().filter((el:any)=>el.get('alias') !== 'draws')]
      if(draws.length){
          source.clear();
          source.addFeatures(draws)
      }else{
          source.clear();
      }
    }

  }, [panoramaTransactStatus])


  return (
    <>
      {drawState.drawStateType === DrawStateType.POINT && <DrawPoint source={source} layer={vector}></DrawPoint>}
      {drawState.drawStateType === DrawStateType.LINESTRING && <DrawLinestring source={source}></DrawLinestring>}
      {drawState.drawStateType === DrawStateType.POLYGON && <DrawPolygon source={source}></DrawPolygon>}
      {drawState.drawStateType === DrawStateType.CLEAR && <ClearAll source={source}></ClearAll>}
    </>
  )
}

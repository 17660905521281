import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarWidth: 0,
  isMobileScreen: false,
  isPanoScreen: false,
  mapClicked: false
}
export const styleControlSlice = createSlice({
  name: "styleControl",
  initialState,
  reducers: {
    setSidebarWidth: (state: any, action) => {
      state.sidebarWidth = action.payload
    },
    setIsMobileScreen: (state: any, action) => {
      state.isMobileScreen = action.payload
    },
    setIsPanoScreen: (state: any, action) => {
      state.isPanoScreen = action.payload
    },
    setMapClicked: (state: any, action) => {
      state.mapClicked = action.payload
    }
  },
});

export const {
  setSidebarWidth, setIsMobileScreen, setIsPanoScreen, setMapClicked
} = styleControlSlice.actions
export default styleControlSlice.reducer;
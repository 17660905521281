import { useEffect, useRef, useState } from 'react';
import './RoadProfile.scss';
import * as d3 from 'd3';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { ButtonState } from '../../../shared/button-state/ButtonState';
import { setButtonState } from '../../../../state/features/buttonStateSlice';
import { HProfileChart } from './HProfileChart';
import { log } from 'console';
import { setMouseMode } from '../../../../state/features/roadProfile';
import { useAppSelector } from '../../../../state/hooks';

interface Props {
    data: any[]
}










interface Options {
    width: number;
    height: number;
    rawLineColor?: string
    rawLineSize?: string,
    margins: any,
    content: any
}













export const RoadProfile = (props: Props) => {
    const svgContainerRef = useRef<any>();
    let mouseAltitude = '-'
    let maxAlt = '-'
    let deltaAltGlob = '-'
    let minAlt = '-'
    let slopeGlob = '-'

    // const [options, setOptions] = useState<any>(null)
    // const [minX, setMinX] = useState<any>(0)
    // const [maxX, setMaxX] = useState<any>(0)
    // const [minY, setMinY] = useState<any>(0)
    // const [maxY, setMaxY] = useState<any>(0)

    // const [maxAlt, setMaxAlt] = useState<any>('-')
    // const [minAlt, setMinAlt] = useState<any>('-')
    const [cachedInfo, setCachedInfo] = useState<any>(null)

    let options: Options | null = null;
    let minX: any = 0;
    let maxX: any = 0;
    let minY: any = 0;
    let maxY: any = 0;

    let x: any = 0;
    let y: any = 0;
    let lastData: any = null;
    let yAxis: any = null;
    let xAxis: any = null;
    let makeXgridlines: any = null;
    let makeYgridlines: any = null;
    let smoothLine: any = null;
    let selectChart: any = null;
    let zoom: any = null;
    let svg: any = null;
    let MOUSE_DATA: any = {
        selectChart: undefined,
        firstIndex: undefined,
        index: undefined
    }


    const TO_RAD = Math.PI / 180
    const TO_DEG = 180 / Math.PI


    // const hprofile: HProfileChart
    // selected = false;
    const dispatch = useDispatch();
    const mouseMode = useAppSelector(state => state.roadProfile.mouseMode);

    useEffect(() => {
        if (svgContainerRef && props.data) {
            const svgContainer = svgContainerRef.current;
            draw2(svgContainer, props.data);

            // this.profileService.overSubscribe.subscribe(({ over }) => {

            //     const profileGeom = this.profileService.currentProfile;
            //     if (!profileGeom) { return; }

            //     if (over) {
            //         profileGeom.showLineCursor();
            //     } else {
            //         profileGeom.hideLineCursor();
            //     }
            //     this.profileService.dirty = new Date();
            // });

            // this.profileService.mouseMove.subscribe(({ lon, lat, alt }) => {

            //     const profileGeom = this.profileService.currentProfile;
            //     if (!profileGeom) { return; }

            //     profileGeom.setLabelAt({ lon, lat, alt });
            // });
        }

    }, [svgContainerRef, props.data])

    const draw2 = (svgContainer: HTMLDivElement, data: Array<any>) => {
        const margins = {
            top: 20,
            right: 20,
            bottom: 10,
            left: 50
        }
        const chartWidth = 700
        const chartHeight = 400
        const svg = d3.create('svg')
            .attr('width', chartWidth)


        const optionss: any = {
            content: svg,
            width: chartWidth,
            height: chartHeight,
            margins,
            rawLineColor: '#ff9a03'
        }

        options = optionss

        // const hprofile = HProfileChart({svgContainer, options});
        setData(data, options, svgContainer);
        // this.hprofile = hprofile


    }

    const onKeyDown = (e: any) => {
        if (e.keyCode === 17) { setSelect(true); }
    }

    const onKeyUp = (e: any) => {
        if (e.keyCode === 17) { setSelect(false); }
    }

    // ngOnDestroy() {
    //     document.removeEventListener('keydown', this.onKeyDown)
    //     document.removeEventListener('keyup', this.onKeyUp)
    // }



    const onDrag = () => {

        const data = lastData
        const firstIndex = MOUSE_DATA.firstIndex
        const currentIndex = MOUSE_DATA.index

        if (firstIndex == null) {
            return
        }

        if (firstIndex === currentIndex) {
            return
        }

        const minInx = Math.min(currentIndex, firstIndex)
        const maxInx = Math.max(currentIndex, firstIndex) + 1
        const newData = data.slice(minInx, maxInx)
        updateSelect(newData)
    }

    const updateSelect = (newData: any) => {
        selectChart
            .datum(newData)
            .enter()
            .append('path')
            .merge(selectChart)
            .attr('d', smoothLine)
        //reacta cevir
        // this.emit({ type: 'onSelectData', data: newData })
    }

    const zoomed = () => {
        if (MOUSE_DATA.onCTRLKeyDown) {
            return;
        }

        d3.select('#chartArea')
            .attr('transform', d3.event.transform)
            .style('stroke-width', 2 / d3.event.transform.k);

        d3.select('.data_circle')
            .attr('r', 7 / d3.event.transform.k)
            .style('stroke-width', 2 / d3.event.transform.k)

        selectChart
            .attr('transform', d3.event.transform)
            .style('stroke-width', 2 / d3.event.transform.k);

        d3.select('.data_label')
            .style('font-size', 20 / d3.event.transform.k)
            .attr('transform', `translate(${8 / d3.event.transform.k}, ${-5 / d3.event.transform.k})`)

        d3.select('.mouse-line')
            .style('stroke-width', 1 / d3.event.transform.k)

        d3.select('#rawLine')
            .attr('transform', d3.event.transform)
            .style('stroke-width', 2 / d3.event.transform.k);

        d3.select('#mouseOverlay')
            .attr('transform', d3.event.transform)

        d3.select('#gxLabel')
            .call(xAxis.scale(d3.event.transform.rescaleX(x)));

        d3.select('#gridXLabel')
            .call(makeXgridlines.scale(d3.event.transform.rescaleX(x)));

        d3.select('#gyLabel')
            .call(yAxis.scale(d3.event.transform.rescaleY(y)));

        d3.select('#gridYLabel')
            .call(makeYgridlines.scale(d3.event.transform.rescaleY(y)));
    }

    const setData = (data: any, options: any, parentContent: any) => {

        lastData = data;
        const { width, height, margins, content }: any = options
        const rawLineColor = options?.rawLineColor === undefined ? '#0000ff' : options.rawLineColor
        const rawLineSize = options?.rawLineSize === undefined ? 2 : options.rawLineSize

        const zoom = d3.zoom()
            .scaleExtent([1, 10])
            .translateExtent([[0, 0], [width, height]])
            .on('zoom', zoomed)

        const svg = content
            .attr('viewBox', [0, 0, width, height])
            .call(zoom)

         minX = d3.min(data, (d: any) => d.x)
         maxX= d3.max(data, (d: any) => d.x)

        const x = d3.scaleLinear()
            .domain([minX, maxX])
            .range([margins.left, width - (margins.right + margins.left)]);


        maxY = d3?.max(data, (d: any) => d.y)
        minY = d3?.min(data, (d: any) => d.y)

        maxY += 2
        minY -= 2
        // maxYGlob = maxY
        // minYGlob = minY

        const y = d3.scaleLinear()
            .domain([maxY, minY])
            .range([margins.top, height - (margins.bottom + margins.top)]);


        const xAxis = d3.axisBottom(x)
        const yAxis = d3.axisLeft(y)

        const rawLine = d3.line()
            .x((d: any) => x(d.x))
            .y((d: any) => y(d.y))

        const smoothLine = d3.line()
            .curve(d3.curveBasis)
            .x((d: any) => x(d.x))
            .y((d: any) => y(d.y))

        const makeXgridlines = d3.axisBottom(x)
            .tickSize(-(height - (margins.bottom + margins.top + margins.top)))
            .tickFormat(null)

        const makeYgridlines = d3.axisLeft(y)
            .tickSize(-(width - (margins.left + margins.left + margins.right)))
            .tickFormat(null)

        svg
            // .append('defs')
            .append('svg:clipPath')
            .attr('id', 'clip')
            .append('svg:rect')
            .attr('id', 'clip-rect')
            .attr('x', margins.left)
            .attr('y', margins.top)
            .attr('width', width - (margins.left + margins.right + margins.left))
            .attr('height', height - (margins.top + margins.top + margins.bottom))
            .attr('fill', 'green')
        // .attr('pointer-events', 'none')

        svg.append('g')
            .attr('id', 'gxLabel')
            .attr('stroke-width', 2)
            .attr('class', 'axis axis--x')
            .attr('transform', `translate(0,${height - (margins.bottom + margins.top)})`)
            .call(xAxis)

        svg.append('g')
            .attr('id', 'gridXLabel')
            .attr('transform', `translate(0,${height - (margins.bottom + margins.top)})`)
            .style('stroke-dasharray', ('3,3'))
            .style('opacity', '0.5')
            .call(makeXgridlines)

        svg.append('g')
            .attr('id', 'gyLabel')
            .attr('stroke-width', 2)
            .attr('class', 'axis axis--y')
            .attr('transform', `translate(${margins.left},0)`)
            .call(yAxis);

        svg.append('g')
            .attr('id', 'gridYLabel')
            .attr('transform', `translate(${margins.left},0)`)
            .style('stroke-dasharray', ('3,3'))
            .style('opacity', '0.5')
            .call(makeYgridlines)

        const chart = svg.append('g')
            .attr('clip-path', 'url(#clip)')

        chart
            .append('path')
            .datum(data)
            .attr('id', 'rawLine')
            .attr('fill', 'none')
            .attr('stroke', '#ff0000')
            .attr('stroke-width', 1)
            .attr('d', rawLine)
            .style('opacity', '0.2');

        chart
            .append('path')
            .datum(data)
            .attr('id', 'chartArea')
            .attr('fill', 'none')
            .attr('stroke', rawLineColor)
            .attr('stroke-width', rawLineSize)
            .attr('d', smoothLine)

        const selectChart = chart
            .append('path')
            .datum([])
            .attr('id', 'selectChart')
            .attr('fill', 'none')
            .attr('stroke', '#ff0000')
            .attr('stroke-width', 4)
            .attr('d', smoothLine)

        const mouseG = chart.append('g')
            .attr('id', 'mouseOverlay')
            .attr('class', 'mouse-over-effects');

        mouseG.append('path')
            .attr('class', 'mouse-line')
            .style('stroke', 'white')
            .style('stroke-width', '1px')

        mouseG.append('svg:rect')
            .attr('x', margins.left)
            .attr('y', margins.top)
            .attr('id', 'mouseRect')
            .attr('width', width - (margins.left + margins.right + margins.left))
            .attr('height', height - (margins.top + margins.top + margins.bottom))
            .attr('fill', 'none')
            .attr('pointer-events', 'all')
            .on('mousemove', mouseMove)
            .on('mouseover', mouseOver)
            .on('mouseout', mouseOut)
            .on('mousedown', mouseDown)

        mouseG
            .data([{ x: 20, y: 0 }])
            .enter()
            .append('g')
            .attr('class', 'mouse-line')
            .attr('id', 'mpl')

        const labelGroup = mouseG.append('g')
            .attr('class', 'label_group')
        // .attr('pointer-events', 'none')

        labelGroup
            .append('circle')
            .attr('r', 7)
            .attr('class', 'data_circle')
            .style('stroke', rawLineColor)
            .style('fill', 'none')
            .style('stroke-width', '2px')

        labelGroup.append('text')
            .attr('class', 'data_label')
            .attr('transform', `translate(8, -5)`)
            .style('font-family', 'sans-serif')
            .style('font-size', 20)
            .style('fill', 'white')

        document.addEventListener('mouseup', onMouseUp)

        parentContent.appendChild(content.node());


        minAlt = minY.toFixed(3)
        maxAlt = maxY.toFixed(3)

        let firstPoint = data[0]
        let lastPoint = data[data.length - 1]
        let deltaAlt = Math.abs(firstPoint.y - lastPoint.y)
        let distance = haversine(firstPoint.lat, lastPoint.lat, firstPoint.lon, lastPoint.lon)
        deltaAltGlob = (deltaAlt).toFixed(3);
        const slope = (deltaAlt / distance) * 100;
        slopeGlob = slope.toFixed(1);

        const cachedInfo = {
            slope,
            minY: minAlt,
            maxY: maxAlt
        }
        setCachedInfo(cachedInfo)

    }

    const setSelect = (b: any) => {

        if (MOUSE_DATA.onCTRLKeyDown !== b) {
            MOUSE_DATA.onCTRLKeyDown = b;
            if (b) {
                svg.on('.zoom', null);
            } else {
                svg.on('.zoom', null);
                svg.call(zoom)
            }

        }

    }

    useEffect(() => {
        if (mouseMode && cachedInfo) {
            if (mouseMode.type === 'onMouseMove') {
                const { lon, lat, alt } = mouseMode.closeData

                mouseAltitude = alt.toFixed(3)
                // profileService.movePointer(lon, lat, alt)
                // } else if (event.type === 'onMouseOut') {
                //     this.profileService.pointerOutChart()
                //     this.mouseAltitude = '-'
                // } else if (event.type === 'onMouseOver') {
                //     this.profileService.pointerOverChart()
                // } else if (event.type === 'onSelectData') {

                //     const newData = event.data
                //     if (newData && newData.length === 0) {
                //         const { minY, maxY } = cachedInfo
                //         this.slope = cachedInfo.slope.toFixed(1);
                //         this.minAlt = minY
                //         this.maxAlt = maxY
                //         this.selected = false;
                //     } else {
                //         const ys = newData.map(e => e.y)
                //         const minY = Math.min(...ys)
                //         const maxY = Math.max(...ys)

                //         this.minAlt = minY.toFixed(3)
                //         this.maxAlt = maxY.toFixed(3)

                //         firstPoint = newData[0]
                //         lastPoint = newData[newData.length - 1]
                //         deltaAlt = Math.abs(firstPoint.y - lastPoint.y)
                //         this.deltaAlt = (deltaAlt).toFixed(3);

                //         distance = Utils.haversine(firstPoint.lat, lastPoint.lat, firstPoint.lon, lastPoint.lon)
                //         const fixedSlope = ((deltaAlt / distance) * 100).toFixed(1);
                //         this.slope = fixedSlope;
                //         this.selected = true;
                //     }


            }


            document.addEventListener('keydown', onKeyDown)
            document.addEventListener('keyup', onKeyUp)
        }


    }, [mouseMode,cachedInfo])


    const mouseMove = () => {

        const { width, height, margins, content }: any = options

        const data = lastData
        const mouse = d3.mouse(document.getElementById('mouseRect') as any)
        const mouseLength = x.invert(mouse[0]);
        const bisect = d3.bisector((d: any) => d.x).right;
        const xIndex = bisect(data, mouseLength, 0);
        const closeData = data[xIndex];

        if (!closeData) { return }

        const posX = x(closeData.x)
        const posY = y(closeData.y)

        d3.select('.mouse-line')
            .attr('d', function () {
                var d = 'M' + posX + ',' + (height - (margins.top + margins.bottom))
                d += ' ' + posX + ',' + margins.top;
                return d;
            })

        // const MOUSE_DATA = this.MOUSE_DATA
        if (MOUSE_DATA.onCTRLKeyDown) {
            MOUSE_DATA.index = xIndex;
            if (MOUSE_DATA.isMouseDown) {
                if (MOUSE_DATA.firstIndex === undefined) {
                    MOUSE_DATA.firstIndex = xIndex
                }
                onDrag();
            }
        }


        d3.select('.label_group')
            .attr('transform', `translate(${posX},${posY})`)

        d3.select('.data_label')
            .text(y.invert(posY).toFixed(3));

        // this.emit({ type: 'onMouseMove', closeData })
        dispatch(setMouseMode({ type: 'onMouseMove', closeData }))
    }

    const mouseOver = () => {

        d3.select('.mouse-line')
            .style('opacity', 1)


        d3.select('.label_group')
            .style('opacity', 1)

        d3.select('.data_label')
            .style('opacity', 1)

        // if (onMouseOver) onMouseOver();
        // this.emit({ type: 'onMouseOver' })
    }

    const mouseOut = () => {
        // this.emit({ type: 'onMouseOut' })
        dispatch(setMouseMode({ type: 'onMouseOut' }))
    }

    const mouseDown = () => {
        MOUSE_DATA.isMouseDown = true;
        updateSelect([])
    }

    const onMouseUp = () => {
        MOUSE_DATA.isMouseDown = false;
        MOUSE_DATA.firstIndex = undefined;
    }






    const haversine = (lat1: any, lat2: any, lon1: any, lon2: any) => {
        var R = 6371000 // metres
        var phi1 = lat1 * TO_RAD
        var phi2 = lat2 * TO_RAD
        var deltaphi = (lat2 - lat1) * TO_RAD
        var deltalamda = (lon2 - lon1) * TO_RAD
        var a = Math.sin(deltaphi / 2) * Math.sin(deltaphi / 2) + Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltalamda / 2) * Math.sin(deltalamda / 2)
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        return R * c
    }





    return (
        <div className='container'>

            <div className="title">
                <span style={{ color: 'white' }}>
                    YOL PROFİLİ
                </span>
                <i className='pi pi-times' onClick={() => dispatch(setButtonState(ButtonState.NONE))}></i>
            </div>
            <div className="body">
                <div className="body-container">
                    <div ref={svgContainerRef}></div>
                    <div className="info-container">
                        <div className="info-column">
                            <div>Maks Yükseklik</div>
                            {/* <div>{{ maxAlt | toFixed:3}}</div> */}
                        </div>

                        <div className="info-column">
                            <div>Min Yükseklik</div>
                            {/* <div>{{ minAlt | toFixed:3}}</div> */}
                        </div>

                        <div className="info-column">
                            <div>Yükseklik</div>
                            {/* <div>{{ mouseAltitude | toFixed:3}}</div> */}
                        </div>


                        <div className="info-column">
                            <div>Yükseklik Farkı</div>
                            {/* <div>{{ deltaAlt | toFixed:3}}</div> */}
                        </div>

                        <div className="info-column">
                            <div>Eğim</div>
                            {/* <div>{{ slope }}%</div> */}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

import WFS from 'ol/format/WFS';
import GML from 'ol/format/GML';
import MultiPolygon from 'ol/geom/MultiPolygon';
import MultiPoint from 'ol/geom/MultiPoint';
import MultiLineString from 'ol/geom/MultiLineString';
import Feature from 'ol/Feature';
import { useDispatch } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { DigiCallbackContext } from './DigiCallbackContextProvider';
import { GeoJSON } from 'ol/format';
import { MapContext } from '../../map-container/map/MapContext';
import { useAppSelector } from '../../../../state/hooks';
import { DigitizationRestApi } from '../../../../util/restapi/digitization';
import {
  setDigiPopupVisibility,
  setGeomEditStatus,
  setPanoramaTransactStatus,
  setPopupSaveStatus,
  setTransactStatus,
} from '../../../../state/features/digitizationSlice';
import { FeatureRestApi } from '../../../../util/restapi/features';
import { setFeatures } from '../../../../state/features/tableSlice';
import { setSelectedFeature, setVertexFeature } from '../../../../state/features/selectedFeatureSlice';
import { ButtonState } from '../../../shared/button-state/ButtonState';
import { setButtonState } from '../../../../state/features/buttonStateSlice';
import { Polygon } from 'ol/geom';
import calculateResult from './digi-draw/calculateResult';
import WKT from 'ol/format/WKT';
import { GeomDataHolder } from '../../panorama-container/easy-pano-imports';

export enum Mode {
  INSERT = 'INSERT',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}
export enum GeometryType {
  MULTIPOINT = 'MultiPoint',
  MULTILINESTRING = 'MultiLineString',
  MULTIPOLYGON = 'MultiPolygon',
}

interface Props {
  feature?: any;
  mode: Mode;
  source?: any;
  type: any;
  coordinates?: any;
}

export const TransactEdit = (props: Props) => {
  const dispatch = useDispatch();
  const map = useContext(MapContext);
  const digiCallback = useContext(DigiCallbackContext);
  const [selectedFeature]: any = useAppSelector((state) => state.selectedFeature.feature);
  const services: any = useAppSelector((state) => state.layer.services);
  const selectedLayer: any = useAppSelector(state => state.layer.selectedLayer);
  // const [calcFields, setCalcFields] = useState<any>([])

  let layerAndWorkspace: any;

  const calcFunc: any = (feature: any) => {
    return calculateResult(feature);
    // console.log("_calcFields : ",_calcFields);
    // return _calcFields;
    // setCalcFields(_calcFields);
  }


  useEffect(() => {

    const olGeoJson = new GeoJSON();

    const geoJsonFinal: any = olGeoJson.writeFeatureObject(props.feature);

    delete geoJsonFinal.properties.bbox;

    const newFeature: any = new Feature();
    newFeature.setProperties(geoJsonFinal.properties);
    newFeature.setGeometryName('geom');
    newFeature.setId(geoJsonFinal.id);

    // console.log("geoJsonFinal :",geoJsonFinal);

    const type = geoJsonFinal.geometry.type;

    let newGeom: any;

    if (type === GeometryType.MULTIPOINT) {
      newGeom = new MultiPoint(geoJsonFinal.geometry.coordinates);
    } else if (type === GeometryType.MULTILINESTRING) {
      newGeom = new MultiLineString(geoJsonFinal.geometry.coordinates);
    } else if (type === GeometryType.MULTIPOLYGON) {
      newGeom = new MultiPolygon(geoJsonFinal.geometry.coordinates);
    } else if (type === 'MultiPolygon') {
      newGeom = new Polygon(props.feature.getCoordinates());
    }

    newFeature.setGeometry(newGeom);

    newFeature.unset('bbox', true);
    newFeature.unset('key', true);
    newFeature.unset('label', true);
    //@ts-ignore
    newFeature.setProperties(geoJsonFinal.properties);

    // console.log("newFeature.:",newFeature);
    if (newFeature.getGeometry().getType() === "MultiLineString") {
      let geom;
      const wkt = new WKT();
      const _geomClone: any = newFeature.getGeometry().clone()
      geom = new MultiLineString([_geomClone.transform('EPSG:3857', 'EPSG:4326').getCoordinates()]);
      const geomWktLine = wkt.writeGeometry(geom);
      geom.transform('EPSG:4326', 'EPSG:3857');
      const gdh: any = GeomDataHolder.fromWKT(geomWktLine);
      (window as any).panogl.getScalable().getMainSketchLayer().addGlobal(gdh);
      (window as any).panogl.getScalable().getMainSketchLayer().refresh();
    } else if (newFeature.getGeometry().getType() === "MultiPolygon") {
      // console.log("HOOOOOOOOOOOOOOOOOOOOOOOOOP : ")
      let geom;
      const wkt = new WKT();
      const _geomClone: any = newFeature.getGeometry().clone()
      geom = new MultiPolygon(_geomClone.transform('EPSG:3857', 'EPSG:4326').getCoordinates());

      const geomWktLine = wkt.writeGeometry(geom);
      geom.transform('EPSG:4326', 'EPSG:3857');
      const gdh: any = GeomDataHolder.fromWKT(geomWktLine);
      (window as any).panogl.getScalable().getMainSketchLayer().addGlobal(gdh);
      (window as any).panogl.getScalable().getMainSketchLayer().refresh();




      // console.log("HOOOOOOOOOOOOOOOOOOOOOOOOOP : ",_geomClone.getCoordinates());

    }


    calcFunc(newFeature).then((calcFields: any) => {


      // console.log("calcFields :",calcFields);
      let propertyOfFeatures: any[] = []
      // console.log("selectedLayer.fields : ",selectedLayer.fields);

      const filteredFields = selectedLayer.fields.filter((field: any) => field.type !== 'bbox' && field.type != 'geom' && field.type != 'Geom' && field.type !== 'Id' && field.type !== 'CreatedBy' && field.type !== 'CreatedDate' && field.type !== 'TouchBy' && field.type !== 'TouchDate')
      filteredFields.map((field: any) => {
        if (calcFields.hasOwnProperty(keyOfTypes[field.type])) {
          propertyOfFeatures.push({ [field.name]: calcFields[keyOfTypes[field.type]] })
        }
      })
      // console.log("propertyOfFeatures :",propertyOfFeatures);
      // console.log("newFeature :",newFeature);


      const properties = Object.assign({}, ...propertyOfFeatures/*, ...newFeature.getProperties()*/);
      // console.log("properties   : ",{...newFeature.getProperties(), properties});

      // console.log("geoJsonFinal.properties :",geoJsonFinal.properties);
      // console.log("properties :",properties);


      newFeature.setProperties({ ...geoJsonFinal.properties, ...properties })
      // console.log("newFeature :",newFeature);

      // const geoJsonFinal:any = olGeoJson.writeFeatureObject(feature);
      // // console.log("geoJsonFinal.properties :", geoJsonFinal.properties);
      // delete geoJsonFinal.properties.bbox;


      layerAndWorkspace = getLayerAndService(selectedFeature.id, services);

      const formatWFS: any = new WFS();

      const formatGML = new GML({
        featureNS: layerAndWorkspace.service.workspace,
        featureType: layerAndWorkspace.layer.name,
        srsName: 'EPSG:3857',
        hasZ: true,
      })

      const xs = new XMLSerializer();

      const node = formatWFS.writeTransaction(null, [newFeature], null, formatGML);

      const payload = xs.serializeToString(node)

      DigitizationRestApi.digiAddFeature(payload, layerAndWorkspace.service.url).then((res: any) => {
        if (res) {
          dispatch(setPanoramaTransactStatus(false))
        }

        const featureId = res.split('.')[1]
        // if (typeof (window as any).addDigiCallback === 'function') {
        //     (window as any).addDigiCallback(featureId)
        // }
        if (typeof digiCallback.callbackFunction === 'function') {
          digiCallback.callbackFunction(featureId)
        }

             // get map extent to download wfs data to use snap feature
        const currentExtent = (window as any).olMap.getView().calculateExtent((window as any).olMap.getSize())
        // Extend the current extent by adding a buffer
        const buffer = 1000; // Adjust this value as needed
        const extendedExtent = [
        currentExtent[0] - buffer, // minX
        currentExtent[1] - buffer, // minY
        currentExtent[2] + buffer, // maxX
        currentExtent[3] + buffer  // maxY
        ];
        const extendedExtent4326 = extendedExtent;
        

        // FeatureRestApi.getFeatures(layerAndWorkspace.service.url, layerAndWorkspace.layer.name).then((res: any) => {
        //   if (res) {
            /* dispatch(setFeatures(res.data.features)) */

            // map update
            const layersOfMap = map.getLayers();

            layersOfMap.forEach((layer: any) => {
              let source: any = layer.getSource();
              if (source.updateParams !== undefined)
                source.updateParams({ time: Date.now() });
            });

            props.source?.clear()

            dispatch(setPanoramaTransactStatus(undefined))
            dispatch(setTransactStatus(undefined))
            dispatch(setPopupSaveStatus(undefined))
            dispatch(setSelectedFeature([]))
            dispatch(setVertexFeature([]))
            dispatch(setGeomEditStatus(false))
            dispatch(setDigiPopupVisibility(false))
            // dispatch(setSelectedFeature([]))
            dispatch(setButtonState(ButtonState.NONE))
            map.updateSize();
            map.render();
            (window as any).panogl.getScalable().redrawLayers(true)
         
        //   }
        // })
      })
    }
    )
  }, [props.feature]);

  const getLayerAndService = (featureId: string, services: any) => {
    let service;
    let layer;
    const layerName = featureId.split('.')[0]

    for (const item of services) {
      for (const testLayer of item.layers) {
        if (testLayer.name === layerName) {
          layer = testLayer
          service = item
        }
      }
    }
    const obj = {
      layer: layer,
      service: service
    }
    return obj
  }

  const keyOfTypes: any = {
    "Length": "length",
    "Start_Point": "start_Point",
    "End_Point": "End_Point",
    "Middle_Point": "Middle_Point",
    "Area": "Area",
    "Location": "location",
    "Start_Point(X)": "start_Point_X",
    "End_Point(X)": "End_Point_X",
    "Middle_Point(X)": "Middle_Point_X",
    "Location(X)": "locationX",
    "Start_Point(Y)": "start_Point_Y",
    "End_Point(Y)": "End_Point_Y",
    "Middle_Point(Y)": "Middle_Point_Y",
    "Location(Y)": "locationY",
    "Start_Point(Z)": "start_Point_Z",
    "End_Point(Z)": "End_Point_Z",
    "Middle_Point(Z)": "Middle_Point_Z",
    "Location(Z)": "locationZ",
    // "Height": 'Height',
    "Perimeter": 'perimeter'
  }

  return (
    <>
    </>
  )
}
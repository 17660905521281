import { Tile } from 'ol/layer';
import { TileWMS } from 'ol/source';
import { PropsWithChildren, useContext, useEffect, useRef } from 'react'
import { MapContext } from '../MapContext'
import { useAppSelector } from '../../../../../state/hooks';
interface IProps {
    url?: string;
    layername: any;
    serviceId: number;
    tag: string;
    priority?: number;
    visibleDomains: []
}
/**
 * External_layer'ların ows istekleri için yazıldı.
 */
export const OwsLayer = (props: PropsWithChildren<IProps>) => {
    const map = useContext(MapContext)
    const t = useRef<Tile<any> | null>(null);
    const token = useAppSelector((state: any) => state.customize.token);
    useEffect(() => {
        const tileLayer: any = new Tile({
            source: new TileWMS({
                params: {
                    layers: props.layername,
                    token: token,
                    // cql_filter: `${cqlFilter && cqlFilter.length > 0 ? cqlFilter.map((domain: any) => `${domain.filterTag}=${+domain.domainId}`).join(' OR ') : `1=1`}`  // domaine göre filtrele
                },
                url: props.url,
                crossOrigin: "anonymous"
            }),
            zIndex: props.priority ? props.priority : 1
        })
        t.current = tileLayer;
    }, []);
    useEffect(() => {
        const tileLayer = t.current!;
        tileLayer.set('tag', props.tag);
        tileLayer.set('serviceId', props.serviceId);
        tileLayer.setVisible(props.layername)
        tileLayer.getVisible()
        map.addLayer(tileLayer);
        return () => { map.removeLayer(tileLayer) }
    }, [props.tag, props.url, props.layername])
    useEffect(() => {
        const tileLayer = t.current!;
        tileLayer.setVisible(props.layername);
        tileLayer.getSource().updateParams({
            layers: props.layername
        })
    }, [props.layername, props.serviceId])
    return null;
}
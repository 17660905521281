import './Map.scss'
import 'ol/ol.css';
import View from 'ol/View';
import proj4 from 'proj4';
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Map as OlMap } from 'ol';
import { MapContext } from './MapContext';
import { useAppSelector } from '../../../../state/hooks';
import { useDispatch } from 'react-redux';
import { transform } from 'ol/proj';
import { setCursorVisible, setIsPanoMiniScreen, setPointerCoordinate } from '../../../../state/features/panoramaSlice';
import { register } from 'ol/proj/proj4';
import { ButtonState } from '../../../shared/button-state/ButtonState';
//@ts-ignore
import proj4List from 'proj4-list';
import { LayerRestApi } from '../../../../util/restapi/layer';
import { setIsMobileScreen } from '../../../../state/features/styleControlSlice';
interface IProps {
  centerX: number;
  centerY: number;
  zoom: number;
  extent?: Array<any>
}

export const Map = (props: PropsWithChildren<IProps>) => {
  const mapRef = useRef(document.createElement('div'));
  const [olMap, setOlMap] = useState<OlMap | null>(null);
  const dispatch = useDispatch();
  const panoramaCoordinate = useAppSelector(state => state.panorama.panoramaCoordinate);
  const projection = useAppSelector(state => state.panorama.projection);
  const tableVisibility = useAppSelector(state => state.buttonState.tableButton);
  const extent = useAppSelector(state => state.selectedFeature.extent);
  const isPanoMiniScreen = useAppSelector(state => state.panorama.isPanoMiniScreen);
  const buttonState = useAppSelector(state => state.buttonState.buttonState);
  const mapState = useAppSelector(state => state.map.mapState);
  const externalLayerExtent = useAppSelector(state => state.layer.externalLayerExtent);
  const goToSelection: any = useAppSelector((state) => state.table.goToSelection);
  // center: [props.centerX, props.centerY],

  const mouseUpUpdate = () => {
    /**  'window resize'  */
    if(+(window as any).innerWidth <= 640){
      dispatch(setIsMobileScreen(true))
    }else{
      dispatch(setIsMobileScreen(false))
    }
    
    setTimeout(() => {
      olMap?.updateSize();
    }, 100);
  }

  const [resizeObserver, setresizeObserver] = useState(new (window as any).ResizeObserver(mouseUpUpdate.bind(Map)))
  resizeObserver.observe(mapRef.current);

  document.addEventListener('mouseup', mouseUpUpdate)
  window.addEventListener('resize', mouseUpUpdate)

  useEffect(() => {
    getEpsgList()
  }, [])

  const getEpsgList = async () => {
    try {
      const response = await LayerRestApi.getEpsgList();
      const epsgForProj = response.data.map((item: any) => {
        return proj4List[item];
      });
      proj4.defs(epsgForProj);
    } catch (error) {
    }
  }


  useEffect(() => {
    if (tableVisibility) {
      mouseUpUpdate();
    }
  }, [tableVisibility])

  useEffect(() => {
    const username: any = localStorage.getItem('username');

    const coordinates: any = (localStorage && localStorage?.getItem(`lastCoordinate${username}`)) ? localStorage?.getItem(`lastCoordinate${username}`)?.split(',') : ['28.978966', '41.034225']
    let zoomValue: any = (localStorage && localStorage?.getItem('zoom')) ? localStorage?.getItem('zoom') : 6.7

    var numericArray = coordinates.map(function (str: any) {
      return parseFloat(str);
    });

    const locationTransform = transform(numericArray, 'EPSG:4326', 'EPSG:3857');

    const view = new View({
      center: [locationTransform[0], locationTransform[1]],
      zoom: zoomValue,
      // minZoom: props.zoom - 8.5,
      maxZoom: 26,
    });

    const map = new OlMap({
      layers: [],
      target: mapRef.current,
      view: view,
      controls: [],
    });
    (window as any).olMap = map
    setOlMap(map);

    return () => map.dispose()

  }, [mapRef]);


  useEffect(() => {
    olMap?.getView().on('change:resolution', (event) => {
      localStorage.setItem('zoom', event.target.getZoom())
    });

  }, [mapRef, olMap])


  useEffect(() => {
    getCursorCoordinate();
  }, [olMap, projection])

  useEffect(() => {
    if (extent) {
      olMap?.getView().fit(extent, { duration: 500, maxZoom: 17 });
    }
  }, [extent])


  useEffect(() => {
    if (externalLayerExtent.length > 0) {
      olMap?.getView().fit(externalLayerExtent, { duration: 500, maxZoom: 17, padding: [20, 20, 100, 20] });
    }
  }, [externalLayerExtent])

  const getCursorCoordinate = () => {
    olMap?.addEventListener('pointermove', (event: any) => {
      const coordinateTransform = proj4('EPSG:3857', projection, event.coordinate);
      const coordinate = projection === 'EPSG:4326' ? [+coordinateTransform[0].toFixed(6), +coordinateTransform[1].toFixed(6)] : [+coordinateTransform[0].toFixed(4), +coordinateTransform[1].toFixed(4)]
      dispatch(setPointerCoordinate(coordinate));
      dispatch(setCursorVisible(false));
    })
  }

  useEffect(() => {
    if (panoramaCoordinate) {
      const coordTransform = transform
        ([panoramaCoordinate.lon, panoramaCoordinate.lat], 'EPSG:4326', 'EPSG:3857')
      if (coordTransform /*&& !tableVisibility*/) {  /*&& mapState !== "COMPLETE"*/
        olMap?.getView().setCenter(coordTransform)
      }
    }
  }, [panoramaCoordinate])


  return (
    <>
      {buttonState === ButtonState.FULLSCREEN && !isPanoMiniScreen && <div className="vsplitterMap screen-switch-button" onClick={() => dispatch(setIsPanoMiniScreen(!isPanoMiniScreen))}>
        <img src="icons/screen-switch-button.svg" alt="" />
      </div>}
      <div className='Map' ref={mapRef}></div>
      {
        olMap &&
        <MapContext.Provider value={olMap}>
          {props.children}
        </MapContext.Provider>
      }
    </>
  )
}


import './Language.scss';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setButtonState } from '../../state/features/buttonStateSlice';
import { ButtonState } from '../shared/button-state/ButtonState';
import { setCurrentLanguage } from '../../state/features/languageSlice';
import { useAppSelector } from '../../state/hooks';

const languages = [
    { value: 'eng', label: 'ENG', src: 'customize-icons/en.png' },
    { value: 'tr', label: 'TR', src: 'customize-icons/tr.png' },
];

export const Language = () => {
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const isMobileScreen: number = useAppSelector((state: any) => state.styleControl.isMobileScreen)

    const [language, setLanguage] = useState(+(window as any).innerWidth <= 640 ? 'eng' : localStorage.getItem('activeLanguage') || 'tr');
    // const currentLanguage = useAppSelector(state => state.language.value)
    const digiPopupVisibility = useAppSelector((state) => state.digitization.digiPopupVisibility);

    useEffect(() => {
        localStorage.getItem('activeLanguage');
    }, [])

    const changeLanguage = (e: any) => {
        i18n.changeLanguage(e.value);
        setLanguage(e.value);
        dispatch(setCurrentLanguage(e.value))
        localStorage.setItem('activeLanguage', e.value);
    }

    const selectedLanguageTemplate = (option: any, props: any) => {
        if (option) {
            return (
                <div className='country-item-value'>
                    <img alt={option.label} src={option.src} style={{ height: '15px' }} />
                    <div className='lang-span'>{option.label}</div>
                </div>
            );
        }
        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const languageOptionTemplate = (option: any) => {
        return (
            <div className='country-item'>
                <img alt={option.label} src={option.src} style={{ height: '15px' }} />
                <div className='lang-span'>{option.label}</div>
            </div>
        );
    }

    const buttonSelected = (e: any) => {
        i18n.changeLanguage(e.target.value);
        setLanguage(e.target.value);
        localStorage.setItem('activeLanguage', e.target.value);
    }

    return (
        <>
            <div className='Language' onClick={() => dispatch(setButtonState(ButtonState.LANGUAGE))}>
                <Dropdown
                    className='languages-dropdown'
                    value={language}
                    options={languages}
                    onChange={changeLanguage}
                    optionLabel='label'
                    valueTemplate={selectedLanguageTemplate}
                    itemTemplate={languageOptionTemplate}
                    disabled={digiPopupVisibility}
                />
            </div>

        </>
    )
}

